import React from 'react'
import AuthVsUnAuth from '../Components/AuthVsUnAuth/AuthVsUnAuth'
import Footer from '../Components/Footer/Footer'
import NavBar from '../Components/Navbar/Navbar'
import Onlinesales from '../Components/OnlineSales/Onlinesales'
import Ourfeatures from '../Components/OurFeatures/Ourfeatures'
import Pricing from '../Components/Pricing/Pricing'
import SalesPriceTracking from '../Components/SalesPriceTracking/SalesPriceTracking'
import SellerAndInventory from '../Components/SellerAndInventory/SellerAndInventory'
import Testimonial from '../Components/Testimonial/Testimonial'
import Topbody from '../Components/TopBody/Topbody'
import EventCard from '../Components/EventCard/EventCard'
import ElevateBusiness from '../Components/ElevateBusiness/ElevateBusiness'
import DiscoverDecide from '../Components/DiscoverDecide/DiscoverDecide'
import SellerDashboard from '../Components/SellerDashboard/SellerDashboard'
import BrandDashboard from '../Components/BrandDashboard/BrandDashboard'

function Home() {
  return (
    <div>
      <NavBar/>
      <Topbody/>
      <Ourfeatures/>
      <Onlinesales/>
      <SellerAndInventory/>
      <ElevateBusiness/>
      <DiscoverDecide/>
      <SellerDashboard/>
      <BrandDashboard/>
      <AuthVsUnAuth/>
      <SalesPriceTracking/>
      {/* <EventCard/> */}
      <Pricing/> 
      <Testimonial/>
      <Footer/>
    </div>
  )
}

export default Home
