import React, { useState } from "react";
import SignUpRight from "./common/SignUpRight";
import "./signup.scss";
import logo from "../../Assets/LogoBlack.png";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import ParticleBg from "./ParticleBg";

const AsinSearch = () => {
  const [searchValue, setSearchValue] = useState("");
  const [searchValueError, setSearchValueError] = useState(null);
  const [searchListDiv, setSearchListDiv] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [searchResult, setSearchResult] = useState({});
  const [moreDetails, setMoreDetails] = useState(false);
  const [addedProductArray, setAddedProductArray] = useState([]);
  const [submitLoader, setSubmitLoader] = useState("");
  const [captchaValue, setCaptchValue] = useState("");
  const [reVerification, setReVerification] = useState(false);
  const searchChange = (e) => {
    const enteredValue = e.target.value;
    setSearchValue(enteredValue);
    if (enteredValue.trim().length == 10) {
      setSearchValueError(false);
      window.grecaptcha.execute();
    } else {
      setSearchValueError("Please enter correct ASIN number");
    }
  };
  const SearchEnter = (e) => {
    e.preventDefault();
    if (searchValue.trim().length === 10) {
      setSearchValueError(false);

      hitAPI();
      setMoreDetails(false);
    } else {
      setSearchValueError("Please enter correct ASIN number");
    }
  };
  const hitAPI = () => {
    setSearchListDiv(true);
    setSearchLoader(true);
    const params = {
      // api_key: "BCC2E713EDD24F56B52A0F6365F1636E",
      // type: "product",
      // amazon_domain: "amazon.com",
      asin: searchValue.trim(),
      captcha: captchaValue,
    };

    const api = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
    });
    api
      .post("api/asin-search", params)
      .then((response) => {
        // print the JSON response from ASIN Data API
        setSearchLoader(false);
        // console.log(response);
        setSearchResult(response?.data?.data);
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        // catch and print the error
        setSearchLoader(false);
        if (typeof error.response.data.message == "object") {
          // console.log('inside if error : ', typeof(error.response.data.message));
          error.response.data.message.map((message) => {
            toast.error(message);
          });
        } else {
          // console.log('inside error : ', typeof(error.response.data.message));
          toast.error(error.response.data.message);
        }
        setSearchListDiv(false);
        setMoreDetails(false);
      });
  };
  // console.log("72", searchResult);
  // console.log('63', searchResult.product);
  // console.log('64', searchResult.product.main_image.link);

  const productClick = (zz) => {
    // console.log(zz);
    setMoreDetails(true);
  };

  useEffect(() => {
    if (searchResult?.success == false) {
      toast.error("Something went wrong");
      setSearchListDiv(false);
      setMoreDetails(false);
    }
  }, [searchResult?.success]);

  const addProduct = (addedProductLink, addedProductname, addedProductAsin) => {
    // Check if the product already exists in the array
    const productExists = addedProductArray.some(
      (product) =>
        product.link === addedProductLink && product.name === addedProductname
    );
    // console.log("93", addedProductArray.length);

    if (!productExists && addedProductArray.length !== 5) {
      const newProduct = {
        link: addedProductLink,
        name: addedProductname,
        asin: addedProductAsin,
      };
      setAddedProductArray((prevProducts) => [...prevProducts, newProduct]);
      // console.log("Added Product:", newProduct);
    } else if (addedProductArray.length === 5) {
      toast.error("Already 5 Products added");
      // console.log("101", addedProductArray.length);
    } else {
      toast.error("Product already Added");
    }
  };

  // console.log("Added Product link:", addedProductArray);

  const removeProduct = (index) => {
    const updatedArray = addedProductArray.filter((_, i) => i !== index);
    setAddedProductArray(updatedArray);
  };

  const handleRecaptchaChange = (value) => {
    setCaptchValue(value);
  };

  const navigate = useNavigate();

  const submitAsin = () => {
    setSubmitLoader(true);
    setReVerification(true);
    window.grecaptcha.execute();
    // if (captchaValue !== null) {
    //   asinStoreHit()
    // } else {
    //   toast.error("Captcha verification expired, submit again to proceed");
    //   setReVerification(false);
    //   window.grecaptcha.execute();
    //   asinStoreHit();
    //   Reverification();
    // }
  };

  // const Reverification = () => {
  //   window.grecaptcha.execute();
  // };

  useEffect(() => {
    if (captchaValue?.length > 0 && reVerification === true) {
      asinStoreHit();
    }
  }, [captchaValue, reVerification]);

  const asinStoreHit = () => {
    // setSubmitLoader(true);
    const sessionID = Cookies.get("UUID");
    const asins = addedProductArray.map((product) => product.asin);
    const asinSubmitArray = {
      uuid: sessionID,
      asins: asins,
      captcha: captchaValue,
    };

    const api = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
    });

    api
      .post(`/api/store-track-asins`, asinSubmitArray)
      .then((response) => {
        // console.log(response);
        setSubmitLoader(false);
        toast.success(response.message);
        // Cookies.set("step", "StepThreeDone", { expires: 7 });
        const secretKey = "Klizo";
        const encrypteStep = CryptoJS.AES.encrypt(
          "StepThreeDone",
          secretKey
        ).toString();
        Cookies.set("step", encrypteStep, { expires: 7 });
        setTimeout(() => {
          window.location.reload();
        }, 100);
      })
      .catch((error) => {
        setSubmitLoader(false);
        // console.log(error);
        toast.error(error?.response?.data?.message);
      });
  };
  const skipSearch = () => {
    const secretKey = "Klizo";
    const encrypteStep = CryptoJS.AES.encrypt(
      "StepThreeDone",
      secretKey
    ).toString();
    Cookies.set("step", encrypteStep, { expires: 7 });
    // Cookies.set("step", "StepThreeDone", { expires: 7 });
    setTimeout(() => {
      // navigate("/seller-details");
      window.location.reload();
    }, 100);
  };
  // console.log(searchResult?.attributes,'217');await console.log(container);
  return (
    <div className={`signUpFlow py-5 ${moreDetails && "align-items-start"}`}>
      <ParticleBg />
      <div className="container position-relative pt-5">
        <div className="row g-4 g-xl-5 align-items-start">
          <div className="col-12 mt-lg-0">
            <div className="row">
              <div className="col-auto">
                <img src={logo} alt="" className="img-fluid spLogo" />
              </div>
              <div className="col-lg-6 ms-auto">
                <div className="offer_bar sign_up">
                    <h5>Get $50 off at sign-up, use code <span className="shadow">Nformed50</span></h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="signUpLeft pt-5">
              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <div className="d-flex align-items-center mb-4">
                    <h5 className="mb-0 me-auto">Track a product</h5>
                    {/* <Link to="/seller-details" className="text-secondary">
                      Skip
                    </Link> */}
                    <button
                      type="button"
                      className="btn p-0 text-secondary border-0"
                      onClick={skipSearch}
                    >
                      Skip
                    </button>
                  </div>
                  <div className="customSearch">
                    <Form>
                      <i className="bi bi-search"></i>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="e.g: B07XQHL645"
                        value={searchValue}
                        onChange={searchChange}
                        disabled={addedProductArray.length === 5 ? true : false}
                      />
                      <button
                        className="btn btn-main"
                        type="submit"
                        onClick={SearchEnter}
                        disabled={
                          searchLoader || addedProductArray.length === 5
                            ? true
                            : false
                        }
                      >
                        Search
                      </button>
                      <ReCAPTCHA
                        sitekey="6LeQVFYpAAAAABj2fVpd9l9gJnSkugoaRgqYONv0"
                        onChange={handleRecaptchaChange}
                        size="invisible"
                      />
                    </Form>
                  </div>
                  {searchValueError && (
                    <small className="text-danger">{searchValueError}</small>
                  )}
                  {searchListDiv && (
                    <div className="searchListDiv">
                      {!searchLoader && (
                        <div
                          className="searchListBlock"
                          onClick={() => productClick(searchResult?.asin)}
                        >
                          <div className="img">
                            <img
                              src={searchResult?.main_image}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <p className="text-truncate">
                            {searchResult?.title}{" "}
                          </p>
                          <small className="d-none">{searchResult?.asin}</small>
                          <i className="bi bi-arrow-up-right"></i>
                        </div>
                      )}
                      {/* Loader */}
                      {searchLoader && (
                        <div className="searchLoading text-center py-4">
                          <Spinner animation="grow" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </div>
                      )}

                      {/* No result */}
                      {/* <div className="noResult text-center">
                                    <i class="bi bi-exclamation-circle display-4 text-danger"></i>
                                    <p className='mb-1 text-danger'>No Result Found</p>
                                    <small>Please check your ASIN number properly and search again</small>
                                </div> */}
                    </div>
                  )}
                  {addedProductArray.length > 0 && (
                    <div className="addedProducts mt-4">
                      <div className="row align-items-center mb-3">
                        <div className="col-auto me-auto">
                          <span>
                            Added products: {addedProductArray.length}
                          </span>
                        </div>
                        <div className="col-auto">
                          <small>Add upto 5 ASINs</small>
                        </div>
                      </div>
                      {addedProductArray.map((data, index) => (
                        <div className="addedProductBlock mb-2" key={index}>
                          <img src={data?.link} alt="" className="img-fluid" />
                          <p className="text-truncate mb-0" title={data?.name}>
                            {data?.name}
                          </p>
                          <small className="me-2" style={{ fontSize: "12px" }}>
                            ({data?.asin})
                          </small>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => removeProduct(index)}
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                  {addedProductArray.length > 0 && (
                    <div className="text-end mt-5 pt-lg-5">
                      <button
                        type="button"
                        className="btn btn-main fs-6 radius-10 px-4 py-2"
                        onClick={submitAsin}
                        disabled={submitLoader ? true : false}
                      >
                        Submit
                        {submitLoader && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="ms-2"
                          />
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            {!moreDetails && <SignUpRight />}
            {moreDetails && (
              <div className="moreDetails p-4 p-lg-5">
                <div className="moreDetailsHeader">
                  <h6 className="text-truncate">
                    Showing Result -{" "}
                    <span title={searchResult?.title}>
                      {searchResult?.title}
                    </span>
                  </h6>
                </div>
                <div className="moreScroll new_scroll">
                  <div className="moreDetailsMain my-4">
                    <div className="row g-4 align-items-center">
                      <div className="col-lg-4">
                        <div className="img">
                          <img
                            src={searchResult?.main_image}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="content">
                          <h6
                            className="text-truncate"
                            title={searchResult?.title}
                          >
                            {searchResult?.title}{" "}
                          </h6>
                          {searchResult?.attributes?.length > 0  && searchResult?.attributes !== 'N/A' ? (
                            searchResult.attributes.map((attribute, index) => (
                              <p key={index} className="my-2">
                                <strong>{attribute.name}:</strong> {attribute.value}
                              </p>
                            ))
                          ) : (
                            <></> 
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="moreDetailsFooter">
                    <h6 className="fw-bold">Description:</h6>
                    <p>{searchResult?.description}</p>
                  </div>
                </div>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-main radius-10 px-5 py-2 fs-6 my-3"
                    onClick={() =>
                      addProduct(
                        searchResult?.main_image,
                        searchResult?.title,
                        searchResult?.asin
                      )
                    }
                    disabled={
                      addedProductArray.some(
                        (product) =>
                          product.link === searchResult?.main_image &&
                          product.name === searchResult?.title
                      )
                        ? true
                        : false
                    }
                  >
                    {addedProductArray.some(
                      (product) =>
                        product.link === searchResult?.main_image &&
                        product.name === searchResult?.title
                    )
                      ? "Added"
                      : "Add"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AsinSearch;
