import React, { useState, useEffect } from "react";

const Timer = () => {
  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((currentSeconds) =>
        currentSeconds > 0 ? currentSeconds - 1 : 0
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return <span>00 : {seconds}</span>;
};

export default Timer;
