import React from 'react'

const CheckULIcon = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={10} cy={10} r={10} fill="#62C192" fillOpacity="0.22" />
      <path
        d="M15 6.5L8.125 13.5L5 10.3182"
        stroke="#62C192"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  )
}

export default CheckULIcon