import { Col, Container, Image, Row } from "react-bootstrap";
import Acordion from "../Components/Accordian/Accordion";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/Navbar/Navbar";
import SideImage from '../Assets/Accept terms-bro.png'
import NewNavbar from "../Components/Navbar/NewNavbar";
import InnerPageFooter from "../NewComponents/InnerPageFooter";

function Terms() {
  const data = [
    {
      title: "Accounts",
      body: `When you create an account with us, you guarantee that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service.

      You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
      `,
    },
    {
      title: " Intellectual Property",
      body: `The Service and its original content, features, and functionality are and will remain the exclusive property of nformed and its licensors. The Service is protected by copyright, trademark, and other laws of both the USA and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of nformed.
      `,
    },
    {
      title: " Links to Other Websites",
      body: `Our Service may contain links to third-party websites or services that are not owned or controlled by nformed.

      nformed has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. We do not warrant the offerings of any of these entities/individuals or their websites.

      You acknowledge and agree that nformed shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such third-party websites or services.

We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.

      `,
    },
    {
      title: "Termination",
      body: `We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.

      If you wish to terminate your account, you may simply discontinue using the Service.
      
      All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
      
      `,
    },
    {
      title: "Indemnification",
      body: `You agree to defend, indemnify, and hold harmless nformed and its licensee and licensors, and their employees, contractors, agents, officers, and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password, or b) a breach of these Terms.
      `,
    },
    {
      title: "Limitation of Liability",
      body: `In no event shall nformed, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
      `,
    },
    {
      title: "Disclaimer",
      body: `Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.

      nformed, its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure, or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.
      
      `,
    },
    {
      title: "Governing Law",
      body: `These Terms shall be governed and construed in accordance with the laws of U.S.A, without regard to its conflict of law provisions.

      Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding the Service.
      
      `,
    },
    {
      title: "Changes",
      body: `We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.
      `,
    },
  ];
  return (
    <div>
      {/* <NavBar /> */}
      <div className="body mb-5" style={{paddingTop: '100px'}}>
      <NewNavbar/>
        <Container>
          <Row className="align-items-center">
            <Col md={5}>
            <h4 className="text-center mt-md-5 mt-sm-3" style={{fontFamily: "'Montserrat', sans-serif"}}>
                Terms & Conditions
              </h4>
               <p className="m-4 p-3">
                  Please read these Terms and Conditions carefully before using
                  the https://nformed.com/ website (together, or individually,
                  the "Service") operated by nformed. Your access to and use of
                  the Service is conditioned upon your acceptance of and
                  compliance with these Terms. These Terms apply to all
                  visitors, users, and others who wish to access or use the
                  Service. By accessing or using the Service, you agree to be
                  bound by these Terms. If you disagree with any part of the
                  terms, then you do not have permission to access the Service.
                </p>
              <Image src={SideImage} className="img-fluid"/>
             
            </Col>
            <Col md={7}>
              <div className="termsBody">
                
                <Acordion data={data} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <Footer /> */}
      <InnerPageFooter/>
    </div>
  );
}

export default Terms;
