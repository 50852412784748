import React, { useEffect, useRef, useState } from "react";
import logo from "../../Assets/LogoBlack.png";
import welcomeImage from "../../Assets/nformed_welcome_image.png";
import "./signup.scss";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import ParticleBg from "./ParticleBg";

const Welcome = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [passwordLoader, setPasswordLoader] = useState(false);
  const [paymentStatusLoader, setPaymentStatusLoader] = useState(true);
  const sessionID = Cookies.get("UUID");
  const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
  });

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  useEffect(() => {
    const checkHeader = {
      uuid: sessionID,
    };
    api
      .post(`/api/check-payment-status`, checkHeader)
      .then((response) => {
        setPaymentStatusLoader(false);
        // toast.success(response?.data?.message);
        // console.log('inside setPassword');
        // Cookies.set("step", "StepFourDone", { expires: 7 });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        Cookies.remove("step");
        Cookies.remove("uuid");
        // console.log(error);
        window.location.reload();
        setPaymentStatusLoader(false);
        // setTimeout(() => {
        // }, 500);
      });
  }, []);
  const userName = Cookies.get("first_name");
  return (
    <div className="membershipPage">
      <ParticleBg />
      <div className="container position-relative">
        <div className="mbHeader">
          <img src={logo} alt="" className="img-fluid" />
        </div>
        <div className="mbBody welcome">
          <h3>Welcome to nformed, {userName.length > 0 && userName}!</h3>
          <div className="row justify-content-center mt-4 mb-4">
            <div className="col-lg-10">
              <div className="video-container">
                <video
                  ref={videoRef}
                  width="100%"
                  height="auto"
                  controls={isPlaying ? "control" : ""}
                  poster={welcomeImage}
                  // onClick={handlePlayPause}
                >
                  <source
                    src="https://res.cloudinary.com/klizo-solutions/video/upload/v1678960536/mov_bbb_fwtuzz.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>

                {!isPlaying && (
                  <div className="play-button d-none" onClick={handlePlayPause}>
                    <i className="bi bi-play-fill"></i>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="text-center mt-3">
            <Link
              className="btn btn-main px-4"
              to={process.env.REACT_APP_BACKEND_URL}
            >
              Go to Dashboard
            </Link>
            {/* <h3 style={{ fontSize: "24px" }}>
              We're setting up your account, check your email for more info :)
            </h3> */}
          </div>
        </div>
      </div>
      {paymentStatusLoader && (
        <>
          <div className="paymentLoader flex-column top-0">
            <img
              src={logo}
              className="img-fluid mb-3"
              alt=""
              style={{ maxWidth: "120px" }}
            />
            <Spinner animation="grow" role="status" variant="secondary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </>
      )}
    </div>
  );
};

export default Welcome;
