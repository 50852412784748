import React from "react";
import "./notFound.css";

const NotFound = () => {
  return (
    <div className="NoPage">
      <div className="container">
        <h1>:(</h1>
        <br />
        <h2>
          A <span>404</span> error occured, Page not found, check the URL and
          try again.
        </h2>
        <br />
        <br />
        <h3>
          <a href="/">Return to home</a>&nbsp;|&nbsp;
          <a href="javascript:history.back()">Go Back</a>
        </h3>
      </div>
    </div>
  );
};

export default NotFound;
