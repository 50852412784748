import React from 'react'

const CrossUlIcon = () => {
  return (
    <svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <circle cx={12} cy="12.5" r={10} fill="#E13B56" fillOpacity="0.12" />
    <path
        d="M14.5 10L9.5 15M9.49998 10L14.5 15"
        stroke="#E13B56"
        strokeWidth="1.5"
        strokeLinecap="round"
    />
    </svg>

  )
}

export default CrossUlIcon