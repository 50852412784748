import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";

const RedirectComponent = () => {
  const [progressStep, setProgressStep] = useState("");
  const navigate = useNavigate();
  // const encryptedStep = Cookies.get("step");
  // if (encryptedStep) {
  //   const secretKey = "Klizo";
  //   const bytes = CryptoJS.AES.decrypt(encryptedStep, secretKey);
  //   const decryptedStep = bytes.toString(CryptoJS.enc.Utf8);
  //   // const progressStep = decryptedStep;
  //   setProgress(decryptedStep);
  //   console.log("step:", decryptedStep);
  // }
  let progress;
  const encryptedStep = Cookies.get("step");
  // console.log("encryptedStep", encryptedStep);
  if (encryptedStep?.length > 0) {
    const secretKey = "Klizo";
    const bytes = CryptoJS.AES.decrypt(encryptedStep, secretKey);
    const decryptedStep = bytes.toString(CryptoJS.enc.Utf8);
    // console.log("decryptedStep", decryptedStep);
    // setProgressStep(decryptedStep);
    progress = decryptedStep;
  } else {
    progress = "";
  }

  useEffect(() => {
    if (
      !["/", "/copyright", "/terms", "/privacy", "/ces2024"].includes(
        window.location.pathname
      ) &&
      progress?.length > 0
    ) {
      if (progress === "StepOneDone") {
        navigate("/asin-search", { replace: true });
      } else if (progress === "StepTwoDone") {
        navigate("/seller-details", { replace: true });
      } else if (progress === "StepThreeDone") {
        navigate("/membership", { replace: true });
      } else if (progress === "StepFourDone") {
        navigate("/set-password", { replace: true });
      } else if (progress === "StepFiveDone") {
        navigate("/welcome", { replace: true });
      } else if (window.location.pathname === "/sign-up") {
        navigate("/");
      }
    }
  }, [progress, navigate]);

  return null;
};

export default RedirectComponent;
