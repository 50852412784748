import React from 'react'
import UsersIcon from '../Icons/UsersIcon'
import { Col, Container, Image, Row } from 'react-bootstrap';
import { FaStar } from "react-icons/fa";
import testimonialOne from '../Assets/new-landing-page-images/testimonial1.png'
import testimonialTwo from '../Assets/new-landing-page-images/testimonial2.png'
import testimonialThree from '../Assets/new-landing-page-images/testimonial3.png'
import testimonialBG from '../Assets/new-landing-page-images/testimonial background.jpg'
const NewTestimonial = () => {
  return (
    <div className='testiMonial py-5 position-relative' id="testimonial">
        <Container className='py-lg-5'>
            <div className="nfromedGuideHeader text-center mb-5">    
                <h6 className="heroSubHeader">
                    <span className='me-1'><UsersIcon/></span>
                    Our Customers
                </h6>
                <h2>See What Our <br /> Customers Are Saying</h2>
                <p>Here's what some of our customers say about our platform.</p>
            </div>
            <div className="mt-5">
                <Row className="g-3 g-lg-4">
                    <Col lg="4">
                        <div className="testimonialBlock card p-lg-2">
                            <div className="card-header testRating">
                                <span><FaStar /></span>
                                <span><FaStar /></span>
                                <span><FaStar /></span>
                                <span><FaStar /></span>
                                <span><FaStar /></span>
                            </div>
                            <div className="card-body">
                                <p>“Since using Nformed, we've cut our product research time In half and have seen a significant Increase In our sales. The competitive Insights are Invaluable!”</p>
                            </div>
                            <div className="card-footer">
                                <div className="testImage">
                                    <img src={testimonialOne} alt="" className="img-fluid" />
                                </div>
                                <div className="testUserDtl">
                                    <h6>Lisa M.</h6>
                                    <p>PrimeGear Solutions</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg="4">
                        <div className="testimonialBlock card p-lg-2">
                            <div className="card-header testRating">
                                <span><FaStar /></span>
                                <span><FaStar /></span>
                                <span><FaStar /></span>
                                <span><FaStar /></span>
                                <span><FaStar /></span>
                            </div>
                            <div className="card-body">
                                <p>“Nformed's product recommendations have been spot on. We've been able to Identify high-margin products quickly and stay ahead of our competitors.”</p>
                            </div>
                            <div className="card-footer">
                                <div className="testImage">
                                    <img src={testimonialTwo} alt="" className="img-fluid" />
                                </div>
                                <div className="testUserDtl">
                                    <h6>John D.</h6>
                                    <p>ApexCommerce</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg="4">
                        <div className="testimonialBlock card p-lg-2">
                            <div className="card-header testRating">
                                <span><FaStar /></span>
                                <span><FaStar /></span>
                                <span><FaStar /></span>
                                <span><FaStar /></span>
                                <span><FaStar /></span>
                            </div>
                            <div className="card-body">
                                <p>“The comprehensive market data and ASIN tracking features have transformed our business strategy. Nformed Is a must-have tool for any serious Amazon seller.”</p>
                            </div>
                            <div className="card-footer">
                                <div className="testImage">
                                    <img src={testimonialThree} alt="" className="img-fluid" />
                                </div>
                                <div className="testUserDtl">
                                    <h6>Emily R.</h6>
                                    <p>Velocity Ventures</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    </div>
  )
}

export default NewTestimonial