import React, { useState, useEffect } from "react";
import logo from "../../Assets/LogoBlack.png";
import Form from "react-bootstrap/Form";
import "./signup.scss";
// import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./rangeSlider.scss";
import TooltipSlider, { handleRender } from "./TooltipSlider";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import ParticleBg from "./ParticleBg";
const NewMembership = () => {
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptTermsError, setAcceptTermsError] = useState("");
  const [captchaValue, setCaptchValue] = useState("");
  const [sliderValue, setSliderValue] = useState(100);
  const [planEnterPriseValue, setplanEnterPriseValue] = useState(399);
  const [continueLoader, setContinueLoader] = useState(false);
  const [continueLoader1, setContinueLoader1] = useState(false);
  const [continueLoader2, setContinueLoader2] = useState(false);
  const [planPriceValue, setPlanPriceValue] = useState();
  const [planObject, setPlanObject] = useState({
    uuid: "",
    captcha: "",
    plan_name: "",
    plan_amount: "",
    plan_interval: "",
    plan_key: "",
    is_trial: Boolean,
  });
  const [planAnnual,setPlanAnnual] = useState(false);
  const sessionID = Cookies.get("UUID");
  const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
  });

  const handleChangePlanAnnual = () => {
    setPlanAnnual(!planAnnual);
  }

  const handleAcceptTermsChange = (e) => {
    setAcceptTerms(e.target.checked);
    if (acceptTerms) {
      setAcceptTermsError("Please accept the terms & conditions.");
    } else {
      setAcceptTermsError(false);
    }
  };

  const marks = {
    5: "5",
    30: "30",
    100: "100",
    // 300: "300",
    // 400: "400",
    // 500: "500",
    // 600: "600",
    // 700: "700",
    // 800: "800",
  };

  const SliderChange = (value) => {
    setSliderValue(value);
  };
  //   console.log("35", sliderValue);
  const navigate = useNavigate();

  const planClick = () => {
    // e.preventDefault();
    // setPlanPriceValue(4999);
    if(planAnnual == true) {
      setPlanPriceValue(47999);
    }
    else{
      setPlanPriceValue(4999);
    }
    setSliderValue(0);
    if (acceptTermsError !== false) {
      setAcceptTermsError("Please accept the terms & conditions.");
      toast.error("Please accept the terms & conditions.");
    } else {
      if (captchaValue?.length > 0) {
        beforePaymentClick();
      }
      else{
        window.grecaptcha.execute();
      }
      setContinueLoader(true);
    }
  };

  const planClickTwo = () => {
    // setPlanPriceValue(8999);
    if(planAnnual == true) {
      setPlanPriceValue(86399);
    }
    else{
      setPlanPriceValue(8999 );
    }
    setSliderValue(30);
    if (acceptTermsError !== false) {
      setAcceptTermsError("Please accept the terms & conditions.");
      toast.error("Please accept the terms & conditions.");
    } else {
      if (captchaValue?.length > 0) {
        beforePaymentClick();
      }
      else{
        window.grecaptcha.execute();
      }
      setContinueLoader1(true);
    }
  }

  const planClickThree = () => {
    if(planAnnual == true) {
      setPlanPriceValue(239999);
    }
    else{
      setPlanPriceValue(24999);
    }
    setSliderValue(100);
    if (acceptTermsError !== false) {
      setAcceptTermsError("Please accept the terms & conditions.");
      toast.error("Please accept the terms & conditions.");
    } else {
      if (captchaValue?.length > 0) {
        beforePaymentClick();
      }
      else{
        window.grecaptcha.execute();
      }
      setContinueLoader2(true);
    }
  }


  const beforePaymentClick = () => {
    const sessionID = Cookies.get("UUID");
    // let planPriceValue = 0;
    // if(sliderValue === 5){
    //   planPriceValue = 4999;
    // }else if(sliderValue === 30){
    //   planPriceValue = 8999;
    // }else{
    //   planPriceValue = 24999;
    // }
    const newPlanObject = {
      uuid: sessionID,
      captcha: captchaValue,
      plan_name: "Professional",
      plan_amount: planPriceValue,
      // plan_amount: {sliderValue === 5 ? (49.99) : (sliderValue === 30 ? (89.99) : (249.99))},
      plan_interval: planAnnual ? 'annually' : 'monthly',
      plan_key: planAnnual ? `${sliderValue}_asins_yearly_tier` : `${sliderValue}_asins_tier`,
      is_trial: false,
    };

    setPlanObject(newPlanObject);
    // console.log(newPlanObject);

    const api = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
    });

    console.log(newPlanObject);

    api
      .post(`/api/proceed-to-payment`, newPlanObject)
      .then((response) => {
        setContinueLoader(false);
        toast.success(response?.data?.message);
        const secretKey = "Klizo";
        const encrypteStep = CryptoJS.AES.encrypt(
          "StepFiveDone",
          secretKey
        ).toString();
        Cookies.set("step", encrypteStep, { expires: 7 });
        Cookies.set("payment_page", "failure", { expires: 7 });
        setTimeout(() => {
          const redirectUrl = response?.data?.redirect_url;
          if (redirectUrl) {
            window.location.href = redirectUrl;
          }
        }, 100);
      })
      .catch((error) => {
        setContinueLoader(false);
        setContinueLoader1(false);
        setContinueLoader2(false);
        toast.error(error?.response?.data?.message);
      });

  };

  const handleRecaptchaChange = (value) => {
    setCaptchValue(value);
  };

  // useEffect(() => {
  //   if (sliderValue === 0) {
  //     setSliderValue(100);
  //     setplanEnterPriseValue(399);
  //   }
  //   const basePrice = 299;
  //   const priceIncrement = 150;
  //   const calculatePlanValue = () => {
  //     return basePrice + Math.floor((sliderValue - 100) / 100) * priceIncrement;
  //   };
  //   setplanEnterPriseValue(calculatePlanValue());
  // }, [sliderValue]);

  useEffect(() => {
    if (captchaValue?.length > 0) {
      beforePaymentClick();
    }
  }, [captchaValue]);

  // useEffect(() => {
  //   const stepCheck = Cookies.get("step");
  //   // console.log('stepCheck : ' , stepCheck);
  //   if (stepCheck !== "StepFourDone" && stepCheck !== "StepThreeDone") {
  //     const checkHeader = {
  //       uuid: sessionID,
  //     };
  //     api
  //       .post(`/api/check-payment-status`, checkHeader)
  //       .then((response) => {
  //         toast.success(response?.data?.message);
  //         Cookies.set("step", "StepFourDone", { expires: 7 });
  //         // console.log('inside membership');
  //         window.location.reload();
  //       })
  //       .catch((error) => {
  //         // console.log(error);
  //         toast.error(error?.response?.data?.message);
  //         Cookies.remove("step");
  //         Cookies.remove("uuid");
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 500);
  //       });
  //   }
  // }, []);

  return (
    <div className="membershipPage">
      <ParticleBg />
      <div className="container position-relative">
        <div className="mbHeader">
          {/* <img src={logo} alt="" className="img-fluid" /> */}
          <div className="col-12 mt-lg-0 px-0">
            <div className="row">
              <div className="col-auto">
                <img src={logo} alt="" className="img-fluid spLogo" />
              </div>
              <div className="col-lg-7 mt-4 mt-lg-0 ms-auto">
                <div className="offer_bar sign_up">
                    <h5>Get $50 off at sign-up, use code <span className="shadow">Nformed50</span></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mbBody">
          <Form>
            <div className="text-center d-flex flex-column align-items-center mb-2">
              <h4 className="mb-2">Choose your Membership</h4>
              <p style={{ color: "#999", marginBottom: "8px" }}>
                How many ASINs do you want to track per month?
              </p>
              <div className="text-start acceptTerm">
                <Form.Check
                  type="checkbox"
                  id="acceptTermsCheckbox"
                  label={
                    <span>
                      Accept{" "}
                      <a
                        href="/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "var(--primary)",
                          textDecoration: "none",
                        }}
                      >
                        terms
                      </a>{" "}
                      &amp;{" "}
                      <a
                        href="/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "var(--primary)",
                          textDecoration: "none",
                        }}
                      >
                        conditions
                      </a>
                      .
                    </span>
                  }
                  checked={acceptTerms}
                  onChange={handleAcceptTermsChange}
                />
                {acceptTermsError && (
                  <small className="text-danger d-block mt-0">
                    {acceptTermsError}
                  </small>
                )}
              </div>
            </div>

            {/* <div className="row justify-content-center">
              <div className="col-11 col-lg-10 col-xl-8">
                <TooltipSlider
                  min={5}
                  max={100}
                  step={100}
                  marks={marks}
                  defaultValue={5}
                  onChange={SliderChange}
                  onChangeComplete={SliderChange}
                  value={sliderValue}
                  handleRender={handleRender}
                />
              </div>
            </div> */}

            <div className="switchSubduration d-flex  align-items-center justify-content-center mb-4 mb-lg-5">
              <label htmlFor="">Monthly</label>
              <Form.Check 
                type="switch"
                id="custom-switch"
                label=""
                className="mx-2"
                onChange={handleChangePlanAnnual}
              />
              <label htmlFor="">Annually</label>
              {/* <small className="discountBadge">20% off</small> */}
            </div>
            
            <div className="row g-4 mb-5 justify-content-center">

              <div className="col-xl-4 col-lg-6 col-md-6 mb-2 mb-lg-4">
                <div className="packageBlock professional text-start position-relative">

                  <h6 className="pa_title text-start">Entry Level Tier</h6>
                  {planAnnual ? 
                    <>
                      <h2 className="pa_value text-start mb-3">
                        $479.99 <span>/ year</span>
                        <small className="discountBadge fs-6 bg-danger position-absolute top-0 end-0 mt-3 me-3 shadow-sm">20% off</small>
                      </h2>
                    </>
                    :
                    <>
                      <h2 className="pa_value text-start mb-3">
                        $49.99 <span>/ month</span>
                      </h2>
                    </>
                  }
                  {/* {sliderValue === 5 
                  ? 
                  (<>
                    <h6 className="pa_title text-start">Entry Level Tier</h6>
                    <h2 className="pa_value text-start mb-3">
                      $49.99 <span>/month</span>
                    </h2>
                  </>) 
                  : 
                  (sliderValue === 30 
                  ? 
                  (<>
                    <h6 className="pa_title text-start">Mid Level Tier </h6>
                    <h2 className="pa_value text-start mb-3">
                      $89.99 <span>/month</span>
                    </h2>
                  </>) 
                  : 
                  (<>
                    <h6 className="pa_title text-start">Premium Level Tier </h6>
                    <h2 className="pa_value text-start mb-3">
                      $249.99 <span>/month</span>
                    </h2>
                  </>) 
                  )} */}

                  {/* <h6 className="pa_title text-start">Professional</h6>
                  <h2 className="pa_value text-start mb-3">
                    ${planEnterPriseValue} <span>/month</span>
                  </h2> */}

                  {/* <p className="pa_duration text-start">per month</p> */}

                  <button
                    type="button"
                    className="btn"
                    onClick={() => planClick()}
                    disabled={continueLoader || continueLoader1 || continueLoader2 ? true : false}
                  >
                    Get Started
                    {continueLoader && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="ms-2"
                      />
                    )}
                  </button>
                  {/* <button className="btn">Get Started</button> */}

                  <ul className="pa_ul">
                    {/* <li>Tracks up to {sliderValue} ASINs</li> */}
                    <li>See all your ASIN’s currently listed.</li>
                    <li>Identify Top Competitors.</li>
                    <li>View Recommended Items to Source.</li>
                    <li>See Items Frequently Bought with your ASIN’s.</li>
                    <li>Compare your store to your competitors.</li>
                  </ul>

                  {/* Entry Level Tier description */}
                  {/* {sliderValue === 5 ? <>
                    <ul className="pa_ul">
                      <li>See all your ASIN’s currently listed.</li>
                      <li>Identify Top Competitors.</li>
                      <li>View Recommended Items to Source.</li>
                      <li>See Items Frequently Bought with your ASIN’s.</li>
                      <li>Compare your store to your competitors.</li>
                    </ul>
                    </>
                    :
                    null
                  } */}

                  {/* Mid Level Tier description */}
                  {/* {sliderValue === 30 ? <>
                    <ul className="pa_ul">
                      <li>All the features in the Entry Level Tier.</li>
                      <li>Plus 30 ASIN's to track.</li>
                      <li>Analytics Tab to see sales, sellers, inventory, pricing and more.</li>
                      <li>Product Search Tab for detailed ASIN specific data and charts.</li>
                    </ul>
                    </>
                    :
                    null
                  } */}

                  {/* Premium Level Tier description */}

                  {/* {sliderValue === 100 ? <>
                    <ul className="pa_ul">
                      <li>All the features in both Entry and Mid-Level Tiers.</li>
                      <li>Plus 100 ASIN's total to track.</li>
                      <li>Onboarding and training support for the first 30 days.</li>
                    </ul>
                    </>
                    :
                    null
                    } */}
                </div>
              </div>

              <div className="col-xl-4 col-lg-6 col-md-6 mb-2 mb-lg-4">
                <div className="packageBlock professional enterprise text-start position-relative">
                  <>
                    <h6 className="pa_title text-start">Mid Level Tier </h6>
                    {planAnnual ? 
                      <>
                        <h2 className="pa_value text-start mb-3">
                          $863.99 <span>/ year</span>
                          <small className="discountBadge fs-6 bg-danger position-absolute top-0 end-0 mt-3 me-3 shadow-sm">20% off</small>
                        </h2>
                      </>
                      :
                      <>
                        <h2 className="pa_value text-start mb-3">
                          $89.99 <span>/ month</span>
                        </h2>
                      </>
                    }
                  </>
                  <button
                    type="button"
                    className="btn"
                    onClick={() => planClickTwo()}
                    disabled={continueLoader || continueLoader1 || continueLoader2  ? true : false}
                  >
                    Get Started
                    {continueLoader1 && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="ms-2"
                      />
                    )}
                  </button>

                    <ul className="pa_ul">
                      <li>All the features in the Entry Level Tier.</li>
                      <li>Plus 30 ASIN's to track.</li>
                      <li>Analytics Tab to see sales, sellers, inventory, pricing and more.</li>
                      <li>Product Search Tab for detailed ASIN specific data and charts.</li>
                    </ul>

                </div>
              </div>

              <div className="col-xl-4 col-lg-6 col-md-6 mb-2 mb-lg-4">
                <div className="packageBlock professional text-start position-relative">
                  <>
                    <h6 className="pa_title text-start">Premium Level Tier </h6>
                    {planAnnual ? 
                      <>
                        <h2 className="pa_value text-start mb-3">
                          $2399.99 <span>/ year</span>
                          <small className="discountBadge fs-6 bg-danger position-absolute top-0 end-0 mt-3 me-3 shadow-sm">20% off</small>
                        </h2>
                      </>
                      :
                      <>
                        <h2 className="pa_value text-start mb-3">
                          $249.99 <span>/ month</span>
                        </h2>
                      </>
                    }
                  </>

                  <button
                    type="button"
                    className="btn"
                    onClick={() => planClickThree()}
                    disabled={continueLoader || continueLoader1 || continueLoader2 ? true : false}
                  >
                    Get Started
                    {continueLoader2 && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="ms-2"
                      />
                    )}
                  </button>

                  {/* Premium Level Tier description */}
                  <ul className="pa_ul">
                      {/* <li>Tracks up to {sliderValue} ASINs</li> */}
                      <li>All the features in both Entry and Mid-Level Tiers.</li>
                      <li>Plus 100 ASIN's total to track.</li>
                      <li>Onboarding and training support for the first 30 days.</li>
                  </ul>
                </div>
              </div>

              {/* <div className="col-xl-5 col-lg-6 col-md-6 mb-2 mb-lg-4">
                <div className="packageBlock enterprise text-start">
                  <h6 className="pa_title text-start">Enterprise</h6>
                  <h2 className="pa_value text-start mb-3">Custom Pricing</h2>

                  <button type="button" className="btn">
                    Get Started
                  </button>
                  <ul className="pa_ul">
                    <li>Tracks as much ASINs as you want</li>
                    <li>Ability to see in depth competitor analysis</li>
                    <li>Ability to request more data on other businesses</li>
                    <li>Customized Reporting and Dashboards</li>
                    <li>Custom GMI Alerts via email and SMS</li>
                    <li>Brand protection support</li>
                    <li>Access to brand reports</li>
                    <li>Advanced Support & Feature Requests</li>
                  </ul>
                </div>
              </div> */}

            </div>
            <ReCAPTCHA
              sitekey="6LeQVFYpAAAAABj2fVpd9l9gJnSkugoaRgqYONv0"
              onChange={handleRecaptchaChange}
              size="invisible"
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default NewMembership;
