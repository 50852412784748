import { Col, Container, Image, Row } from "react-bootstrap";
import Acordion from "../Components/Accordian/Accordion";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/Navbar/Navbar";
import CopyRightImage from '../Assets/copyright-guy-green.png'
function Copyright() {
  const data =[
    {
      title: "Copyright Infringement Notification",
      body: `If you believe that your copyrighted work has been copied in a way that constitutes copyright infringement and is accessible on our Service, please notify our copyright agent, as set forth in the Digital Millennium Copyright Act of 1998 (DMCA). For your complaint to be valid under the DMCA, you must provide the following information in writing:

      a. An electronic or physical signature of a person authorized to act on behalf of the copyright owner;
      b. Identification of the copyrighted work that you claim has been infringed;
      c. Identification of the material that is claimed to be infringing and where it is located on the Service;
      d. Information reasonably sufficient to permit nformed  to contact you, such as your address, telephone number, and, e-mail address;
      e. A statement that you have a good faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or law; and
      f. A statement, made under penalty of perjury, that the above information is accurate, and that you are the copyright owner or are authorized to act on behalf of the owner.
   
   The above information must be submitted to us by email
   
   Please note that this procedure is exclusively for notifying nformed that your copyrighted material has been infringed.`,

    },
    {
      title: " Counter-Notice",
      body: `If you believe that the material you posted was removed by mistake or misidentification, you may file a counter-notice with our copyright agent. A valid counter-notice must contain the following information in writing:

      a. Your physical or electronic signature;
      b. Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled;
      c. A statement under penalty of perjury that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled;
      d. Your name, address, and telephone number, and a statement that you consent to the jurisdiction of the federal district court for the judicial district in which the address is located, or if your address is outside of the United States, for any judicial district in which nformed may be found, and that you will accept service of process from the person who provided notification under DMCA subsection (c)(1)(C) or an agent of such person.
   
   Upon receiving a valid counter-notice, our copyright agent will forward it to the person who filed the original complaint. If the original complainant does not file a lawsuit within 10 business days, we may, in our sole discretion, restore the removed material.
   `,
   
    },
    {
      title: " Repeat Infringers",
      body: `It is our policy to terminate, in appropriate circumstances, the accounts of users who are repeat infringers`,
   
    },
    {
      title: "Modifications",
      body: `We may revise this Copyright Policy at any time by updating this page. By using our Service, you agree to be bound by the then-current version of this Copyright Policy.`,
    },
  ]
  return (
    <div>
      <NavBar />
      <section className="content_area">
        <Container>
          <Row className="justify-content-center mb-4">
            <Col md={4} >
              <Image src={CopyRightImage} className="img-fluid"/>
            </Col>
            <Col md={8}>
              <div className="copyRightBody">
                <h4 className="text-center mt-md-5 mt-3 mb-5 mb-md-5">
                  Copyright page
                </h4>
                <div className="copyrightContent mt-5 mt-md-5 mb-5">
                  <Acordion data={data}/>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
}

export default Copyright;
