import React from 'react'

const SearchIcon = () => {
  return (
    <svg
    width={31}
    height={30}
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <g clipPath="url(#clip0_2027_300)">
        <circle
        cx="14.9797"
        cy="14.6468"
        r="9.5"
        transform="rotate(-14.59 14.9797 14.6468)"
        stroke="#028143"
        strokeWidth="1.5"
        />
        <path
        d="M25.3467 20.7314L27.786 22.1632"
        stroke="#028143"
        strokeWidth="1.5"
        strokeLinecap="round"
        />
    </g>
    <defs>
        <clipPath id="clip0_2027_300">
        <rect
            width={24}
            height={24}
            fill="white"
            transform="translate(0.953613 6.41455) rotate(-14.59)"
        />
        </clipPath>
    </defs>
    </svg>
  )
}

export default SearchIcon