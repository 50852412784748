import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import freeTrialImage from '../Assets/new-landing-page-images/freeTrial.png'

const FreeTrial = () => {
  return (
    <div className='freeTrial'>
        <Container>
            <div className="freeTrialBlock p-4 p-lg-5">
              <div style={{maxWidth: '800px', margin: '0 auto'}}>
                <img src={freeTrialImage} alt="" className="img-fluid mb-4" />
                <h3 className='mb-4'>Discover Profitable Products & Start Maximizing Your Amazon Sales Today.</h3>
                <p>Spend less time on research and more on growing your Amazon business with Nformed.</p>
                <Link to="/sign-up" className='btn nformed-btn'>Try Nformed Today</Link>
              </div>
            </div>
        </Container>
    </div>
  )
}

export default FreeTrial