import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import './syle.css'
import { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
function  BookFreeDemo (props) {
  const [businessName,setBusinessName] = useState("");
  const [email,setemail] = useState("");
  const [name,setname] = useState("");
  const [message,setmessage] = useState("");
  const [phoneNumber,setphoneNumber] = useState("");
  const [error, setError] = useState(false);
  const [isloading,SetIsLoading] = useState(false);

  const submitDetails = async() => {
    SetIsLoading(true)
    if (businessName.length !== 0 && email.length !== 0  && name.length !== 0  && phoneNumber.length !== 0 ) {
      SetIsLoading(true)
      setError(false)
      try {
       await axios.post(`${process.env.REACT_APP_BASE_URL}/api/membership`,{businessName,email,name,message,phoneNumber}).then((res)=>{
          // console.log("data",res);
          SetIsLoading(false)
          props.onHide();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Success',
            showConfirmButton: false,
            timer: 1500
          })
          
        })
      } catch (error) {
        // console.log(error);
        SetIsLoading(false);
      }
    }else{
      setError(true)
      SetIsLoading(false)
    }
    
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="text-white bg-dark">
        <Modal.Title id="contained-modal-title-vcenter" style={{fontFamily: "'Montserrat', sans-serif"}}>
          Get Membership
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark text-white">
        <FloatingLabel
          controlId="floatingInput"
          label="Business Name"
          className={`mb-3 text-white`}
        >
          <Form.Control
            type="text"
            placeholder="name@example.com"
            className={`bg-transparent text-white ${error === true && businessName <= 0 ? 'border_red' : ''}`}
            name="businessName"
            onChange={(e)=>setBusinessName(e.target.value)}
            value={businessName}
          />
        </FloatingLabel>

        <FloatingLabel
          controlId="floatingInput"
          label="Email"
          className="mb-3 text-white"
        >
          <Form.Control
            type="email"
            placeholder="name@example.com"
            className={`bg-transparent text-white ${error === true && email <= 0 ? 'border_red' : ''}`}
            name="email"
            onChange={(e)=> setemail(e.target.value)}
            value={email}
          />
        </FloatingLabel>

        <FloatingLabel
          controlId="floatingInput"
          label="Name"
          className="mb-3 text-white"
        >
          <Form.Control
            type="text"
            placeholder="name@example.com"
            className={`bg-transparent text-white ${error === true && name <= 0 ? 'border_red' : ''}`}
            name="name"
            onChange={(e) =>setname(e.target.value)}
            value={name}
          />
        </FloatingLabel>

        <div className="phoneNumberT">
        <PhoneInput
          international
          countryCallingCodeEditable={false}
          placeholder="Enter phone number"
          defaultCountry="US"
          className="d-flex align-items-center justify-content-center pt-1 pb-1"
          value={phoneNumber}
          onChange={setphoneNumber}
          error={phoneNumber && !isValidPhoneNumber(phoneNumber) ? 'Invalid phone number' : undefined}
        />
        </div>

        <FloatingLabel
          controlId="floatingTextarea2"
          label="Message Box"
          className="text-white"
        >
          <Form.Control
            as="textarea"
            placeholder="Leave a comment here"
            style={{ height: "100px" }}
            className="bg-transparent text-white"
            name="message"
            onChange={(e) => setmessage(e.target.value)}
            value={message}

          />
        </FloatingLabel>
      </Modal.Body>
      <Modal.Footer className="bg-dark text-white">
        <Button variant="success" onClick={submitDetails} disabled={isloading} className="d-flex align-items-center justify-content-center">
        {isloading ? "loading.." : 'Submit'} {isloading ? <div className="loadingio-spinner-bean-eater-6yekvqukeod"><div className="ldio-fg7oryce9mw">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div> : null} 
        </Button>
        <Button variant="danger" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default BookFreeDemo