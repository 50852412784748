import Acordion from "../Components/Accordian/Accordion";
// import Footer from "../Components/Footer/Footer";
// import NavBar from "../Components/Navbar/Navbar";
import PrivacyPolicy from '../Assets/Privacy policy-bro.png'
import NewNavbar from "../Components/Navbar/NewNavbar";

import { Col, Container, Nav, Row, Image  } from 'react-bootstrap'
import InnerPageFooter from "../NewComponents/InnerPageFooter";

function Privacy() {
  const data = [
    {
      title: " Information Collection and Use",
      body: "We collect two types of information about our users: Personal Information and Non-Personal Information. \n (a) Personal Information: While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information ('Personal Information') may include, but is not limited to: \n - Email address \n - Name - Telephone number \n - Address \n- Date of birth \n - Payment information \n (b) Non-Personal Information: We also collect non-personally identifiable information about your use of our Service, which may include your IP address, browser type, referring exit pages, operating system, date/time stamps, and related data."
    },
    {
      title: "  Log Data",
      body: `We collect information that your browser sends whenever you visit our Service ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.
      `,
    },
    {
      title: "Cookies",
      body: `We use cookies and similar tracking technologies to track the activity on our Service and hold certain information. Cookies are files with a small amount of data, which may include an anonymous unique identifier. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.

      `,
    },
    {
      title: "Use of Collected Information",
      body: `We use the collected information to operate, maintain, and provide the features and functionality of the Service, as well as to communicate with you, and to monitor and improve the Service.
      `,
    },
    {
      title: "Disclosure of Your Information",
      body: `We will not sell or share your personally identifiable information with any third parties without your consent, except as necessary to provide you with the services offered by us or to comply with the law.
      `,
    },
    {
      title: "Service Providers",
      body: `We may employ third-party companies and individuals to facilitate our Service, provide the Service on our behalf, perform Service-related services, or assist us in analyzing how our Service is used. These third parties have access to your Personal Information only to perform specific tasks on our behalf and are obligated not to disclose or use your information for any other purpose.
      `,
    },
    {
      title: "Security",
      body: `The security of your Personal Information is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
      `,
    },
    {
      title: " International Transfer",
      body: `Your information, including Personal Information, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those in your jurisdiction. By using the Service, you consent to the transfer of your information to such countries.
      
      `,
    },
    {
      title: "Children's Privacy",
      body: `Our Service does not address anyone under the age of 13 ("Children"). We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you are aware that your child has provided us with Personal Information, please contact us. If we become aware that we have collected Personal Information from children under 13 without verification of parental consent, we will take steps to remove that information from our servers.
      `,
    },
    {
      title: "Links to Other Sites",
      body: `Our Service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services. We strongly advise you to review the privacy policy of every site you visit.
      `,
    },
    {
      title: "Changes to This Privacy Policy",
      body: `We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
      `,
    },

    {
      title: "Your Rights",
      body: `You have certain rights with respect to your Personal Information, including the right to access, correct, update, or request deletion of your Personal Information. If you wish to exercise these rights, please contact us.
      `,
    },
  ];

  return (
    <div className="body" style={{paddingTop: '110px'}}>
      {/* <NavBar /> */}
      <NewNavbar/>
      <Container>
        <Row className="align-items-center mb-5">
          <Col md={4}>
          <h4 className="text-center mt-md-5 mt-sm-3 mt-2" style={{fontFamily: "'Montserrat', sans-serif"}}>Privacy Policy</h4>
          <p className="m-4 p3">
                nformed operates the https://nformed.com/ website. This Privacy
                Policy outlines the types of information that we gather about
                you while you are using the Service, and the ways in which we
                use and share this information. By using the Service, you agree
                to the collection and use of information in accordance with this
                policy. Unless otherwise defined in this Privacy Policy, terms
                used in this Privacy Policy have the same meanings as in our
                Terms and Conditions, accessible at https://nformed.com/terms.
              </p>
            <Image src={PrivacyPolicy} className="img-fluid"/>
          </Col>
          <Col md={8}>
            
            <div className="privacyBody">
             
              <Acordion data={data} />
            </div>
          </Col>
        </Row>
      </Container>
      <InnerPageFooter/>
    </div>
  );
}

export default Privacy;
