import React from 'react'

const MakeSellIcon = () => {
  return (
    <svg
    width={33}
    height={33}
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <g clipPath="url(#clip0_2027_306)">
        <path
        d="M7.36358 12.6212C9.3379 8.34055 10.3251 6.2002 12.2683 5.48371C14.2114 4.76722 16.3518 5.75438 20.6325 7.72871C24.9131 9.70304 27.0535 10.6902 27.77 12.6334C28.4865 14.5766 27.4993 16.7169 25.525 20.9976C23.5506 25.2783 22.5635 27.4186 20.6203 28.1351C18.6771 28.8516 16.5368 27.8644 12.2561 25.8901C7.97541 23.9158 5.83507 22.9286 5.11858 20.9854C4.40208 19.0423 5.38925 16.9019 7.36358 12.6212Z"
        stroke="#028143"
        strokeWidth="1.5"
        />
        <path
        d="M11.0662 16.5312L14.1086 15.4094C14.6268 15.2183 15.2017 15.4835 15.3928 16.0017L16.1686 18.1059C16.3597 18.624 16.9347 18.8892 17.4529 18.6982L21.8222 17.0871M21.8222 17.0871L20.7751 19.3573M21.8222 17.0871L19.552 16.0401"
        stroke="#028143"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        />
    </g>
    <defs>
        <clipPath id="clip0_2027_306">
        <rect
            width={24}
            height={24}
            fill="white"
            transform="translate(10.5732 0.886719) rotate(24.76)"
        />
        </clipPath>
    </defs>
    </svg>

  )
}

export default MakeSellIcon