import React, { useEffect, useState } from "react";
import NavBar from "../Components/Navbar/Navbar";
import { Button, Col, Container, Row } from "react-bootstrap";
import PlaylistIcon from "../Assets/Playlist.png";
import Finance from "../Assets/FinancialGrowthAnalysis.png";
import CaseStudy from "../Assets/CaseStudy.png";
import TotalSale from "../Assets/TotalSales.png";
import WireHouse from "../Assets/Warehouse.png";
import Form from 'react-bootstrap/Form';
import stayLogo from "../Assets/GroupLogo9086.png";
import "../Assets/coustomPagecss/mobiledisrupt.css";
import Footer from "../Components/Footer/Footer";
import axios from "axios";
import Swal from "sweetalert2";
function Mobiledisrupt() {
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [company, setCompany] = useState("");
  const [term,setTerm] = useState(false);
 const checkTerm = async(e)=>{
  setTerm(e.target.checked);
 }
  const submitData = async()=>{
    const emailRegex = new RegExp(/^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,}$/);
    if (fullname !== "" && email !== "" && company !== "") {
      if (emailRegex.test(email)) {
        if (term) {
          await axios.post(`${process.env.REACT_APP_BASE_URL}/api/memberR`,{"fullName": fullname ,email,website,company}).then((res)=>{
            // console.log(res);
            if (res.data.message === true) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                text: 'We will get back to you Soon',
                showConfirmButton: false,
                timer: 1500
              })
            }else{
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'something went wrong',
                showConfirmButton: false,
                timer: 1500
              })
            }
          }).catch((err)=>{
            // console.log(err);
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'something went wrong',
              showConfirmButton: false,
              timer: 1500
            })
          })
        }else{
          Swal.fire({
            position: 'center',
            icon: 'error',
            text: 'Please accept terms and conditions',
            showConfirmButton: false,
            timer: 1500
          })
        }
       
      }else{
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Please enter a valid email',
          showConfirmButton: false,
          timer: 1500
        })
      }
    
    }else{
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Please fill the all fields',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }


  //  const Emailvalidate = (data) => {
  //   const emailRegex = new RegExp(/^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,}$/);
  //   if (emailRegex.test(data)) {
  //     setEmail(data)
  //   }else{
  //     Swal.fire({
  //       position: 'center',
  //       icon: 'error',
  //       title: 'Please enter valid email',
  //       showConfirmButton: false,
  //       timer: 1500
  //     })
  //   }
  //  }
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },[])
  return (
    <div>
      <NavBar />
     <div className="banner">
     <Container>
      
        <Row>
        <div className="stayWith">
          <div className="stayWithCard">
            <h1>Stay Informed with </h1>
            <img src={stayLogo} alt="" className="img-fluid"/>
          </div>
        </div>
          <Col md={6}>
            <div className="bannerLeftHeader p-md-0">
              <h2>Connect with Us at CES 2024</h2>
              <p>
              We’re thrilled to have connected with you at CES 2024! As innovators and leaders in the ever-evolving landscape of technology and digital solutions, we recognize the critical role of emerging technologies and staying at the forefront of industry advancements.
              </p>
              <Button href="#si" variant="success" className="signup mt-3 mb-3">
                Sign Up Here
              </Button>
              <div className="counters">
                <div className="counter">
                  <div className="baseLinegreen"></div>
                  <h3>700+</h3>
                </div>
                <div className="counter">
                  <div className="baseLinewarning"></div>
                  <h3>1000+</h3>
                </div>
                <div className="counter">
                  <div className="baseLinered"></div>
                  <h3>100+</h3>
                </div>
                <div className="counter">
                  <div className="baseLineviolet"></div>
                  <h3>30+</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="bannerRight d-flex ">
              
            </div>
          </Col>
        </Row>
      </Container>
     </div>

     <Container>
     <Row>
        <div className="powerOfEnformed mb-4">
            <Col md={12}>
              <div className="powerBody text-center pt-3">
                <h2 className="powerBodyHeader">
                  Unlock the Power of nformed!
                </h2>
                <h4 className="powerBodyText">Key Features and Benefits</h4>
              </div>
            </Col>
          </div>
        </Row>
     </Container>
      <div className="tracking">
        <Container className="pt-4 ">
          <Row className="tracking_padding">
            <Col md={4}>
              <div className="card p-4 mt-sm-2">
                <div className="card-icon">
                  <img src={PlaylistIcon} alt="" />
                </div>
                <div className="card_header">
                  <h4>ASIN Tracking</h4>
                </div>
                <div className="card_text">
                  <p>
                    Stay updated on the performance of your ASINs, monitor key
                    metrics, and make data-driven decisions to optimize your
                    sales strategies.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="card p-4 mt-sm-2">
                <div className="card-icon">
                  <img src={Finance} alt="" />
                </div>
                <div className="card_header">
                  <h4>Product Research </h4>
                </div>
                <div className="card_text">
                  <p>
                    Leverage our advanced product research capabilities to
                    identify profitable opportunities, analyze market trends,
                    and discover high-demand products.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="card p-4 mt-sm-2">
                <div className="card-icon">
                  <img src={CaseStudy} alt="" />
                </div>
                <div className="card_header">
                  <h4>Keyword Research</h4>
                </div>
                <div className="card_text">
                  <p>
                    Uncover relevant keywords with our robust keyword research
                    tools, helping you optimize your product listings and
                    improve discoverability
                  </p>
                </div>
              </div>
            </Col>

            <Col md={6}>
              <div className="card p-4 mt-3">
                <div className="card-icon">
                  <img src={TotalSale} alt="" />
                </div>
                <div className="card_header">
                  <h4>Sales Analytics</h4>
                </div>
                <div className="card_text">
                  <p>
                    Stay updated on the performance of your ASINs, monitor key
                    metrics, and make data-driven decisions to optimize your
                    sales strategies.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="card p-4 mt-3">
                <div className="card-icon">
                  <img src={WireHouse} alt="" />
                </div>
                <div className="card_header">
                  <h4>Inventory Management</h4>
                </div>
                <div className="card_text">
                  <p>
                    Leverage our advanced product research capabilities to
                    identify profitable opportunities, analyze market trends,
                    and discover high-demand products.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="signupSection" id="si">
        <Container>
          <Row>
            <Col md={5}></Col>
            <Col md={7} className="d-flex align-items-center justify-content-center">
              <div className="signup_form">

                <div className="form_header">
                  <h2>Sign Up Here</h2>
                  <p>Welcome to our website! To access all our features, please
                    sign up by filling in the form below.</p>
                </div>
                <Form>
                  <Form.Group className="mb-3" controlId="signup">
                    <Form.Control type="text" onChange={(e) => setFullName(e.target.value)} className="mt-2" placeholder="Full Name" />
                    <Form.Control type="text" className="mt-2" onChange={(e) => setCompany(e.target.value)} placeholder="Company" />
                    <Form.Control type="email" className="mt-2" onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                    <Form.Control type="text" className="mt-2" onChange={(e) => setWebsite(e.target.value)} placeholder="Website" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" onChange={(e) => checkTerm(e)} label="I agree to the terms & policy" />
                  </Form.Group>
 
                  <Button onClick={(e)=> submitData()} variant="success" className="w-100" type="button">
                  SIGN UP
                  </Button>

                  <p className="form_bottom">Sign Up Today and Track 100 ASINs Free!</p>
                </Form>

              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="contact_us">
        <Container>
          <Row>
            <Col md={12}>
              <div className="contact_us_body">
                <h2 className="header">
                Contact Us
                </h2>
                <p className="subHeader">
                If you have any questions or need further assistance, our dedicated team is here to help:
                </p>
                <p className="email">
                <span>Email:</span> sales@nformed.com
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer/>
    </div>
  );
}

export default Mobiledisrupt;
