import React from 'react'

const UsersIcon = () => {
  return (
    <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <path
        d="M14.6 13.3999V12.1999C14.5997 11.6682 14.4227 11.1516 14.0969 10.7313C13.7711 10.311 13.3149 10.0109 12.8 9.87793"
        stroke="#028143"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
    />
    <path
        d="M10.9999 13.3998V12.1998C10.9999 11.5633 10.747 10.9528 10.297 10.5027C9.84687 10.0527 9.23642 9.7998 8.5999 9.7998H3.7999C3.16338 9.7998 2.55293 10.0527 2.10285 10.5027C1.65276 10.9528 1.3999 11.5633 1.3999 12.1998V13.3998"
        stroke="#028143"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
    />
    <path
        d="M10.3999 2.67822C10.9162 2.8104 11.3737 3.11064 11.7005 3.53161C12.0272 3.95257 12.2046 4.47032 12.2046 5.00322C12.2046 5.53612 12.0272 6.05387 11.7005 6.47484C11.3737 6.8958 10.9162 7.19604 10.3999 7.32822"
        stroke="#028143"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
    />
    <path
        d="M6.20005 7.4001C7.52553 7.4001 8.60005 6.32558 8.60005 5.0001C8.60005 3.67461 7.52553 2.6001 6.20005 2.6001C4.87457 2.6001 3.80005 3.67461 3.80005 5.0001C3.80005 6.32558 4.87457 7.4001 6.20005 7.4001Z"
        stroke="#028143"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
    />
    </svg>

  )
}

export default UsersIcon