import React, { useEffect, useState } from "react";
import SignUpRight from "./common/SignUpRight";
import "./signup.scss";
import logo from "../../Assets/LogoBlack.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
// import {getBaseUrl} from '../../env';
import brandIcon from "../../Assets/sign/enterprisebrand.png";
import sellerIcon from "../../Assets/sign/seller2.png";
import neitherIcon from "../../Assets/sign/enterprisebrand.png";
import SignUpRightBrand from "./common/SignUpRightBrand";
import SignUpRightSeller from "./common/SignUpRightSeller";
import SignUpNeither from "./common/SignUpNeither";
import ParticleBg from "./ParticleBg";
import Timer from "./common/Timer";

function generateUUID() {
  const specificTimeInIST = new Date();
  const specificTimestampIST = Math.floor(specificTimeInIST.getTime() / 1000);

  const randomAphaNumeric =
    Math.random().toString(36).substring(2, 8) +
    Math.random().toString(36).substring(2, 8);

  const shuffled = (str, num) =>
    (str + num)
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");

  const UUID = shuffled(randomAphaNumeric, specificTimestampIST);
  Cookies.set("UUID", UUID, { expires: 7 }); // Set cookie to expire in 7 days
  return UUID;
}

const SignUpNew = () => {
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [userEmailError, setUserEmailError] = useState(null);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [signUpLoader, setSignUpLoader] = useState(false);
  const [captchaValue, setCaptchValue] = useState("");
  const [accountType, setAccountType] = useState("seller");
  const [website, setWebsite] = useState("");
  const [websiteError, setWebsiteError] = useState(null);
  const [selectedAccountType, setSelectedAccountType] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [viewPass1, setViewPasss1] = useState(false);
  const [sendOTP, setSendOTP] = useState(false);
  const [isOTP, setIsOTP] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [otpError, setOtpError] = useState(null);
  const [sellercode, setSellercode] = useState("");
  const [sellerCodeError, setSellerCodeError] = useState(false);
  const [sellerCodeLoader, setSellerCodeLoader] = useState(false);
  const [sellerInterval, setSellerInterval] = useState(false);
  const [verifiedSeller, setVerifiedSeller] = useState("");

  const navigate = useNavigate();

  const checkSellerID = (e) => {
    const sellerCodevalue = e.target.value;
    const pattern = /^A\w{11,13}$/;
    setSellercode(sellerCodevalue);
    setVerifiedSeller("");
    setSellerCodeLoader(false);
    if (sellerCodevalue.trim().length > 0) {
      if (!pattern.test(sellerCodevalue)) {
        setSellerCodeError("Seller ID not Valid");
      } else {
        setSellerCodeError(false);
      }
    } else {
      setSellerCodeError(false);
    }
  };

  const checkOtp = (e) => {
    let valueOtp = e.target.value;

    if (valueOtp.trim().length > 6) {
      valueOtp = valueOtp.slice(0, 6);
    } else if (valueOtp.trim().length < 6) {
      setOtpError("OTP must consist 6 characters");
    } else {
      setOtpError(false);
    }

    setOtpValue(valueOtp);
  };

  const checkWebsite = (e) => {
    const url = e.target.value;
    setWebsite(url);
    const urlRegex = /^(?:(?:www\.)?)[\w.-]+\.\w{2,}(?:\/.*)?$/i;
    const isValidUrl = urlRegex.test(url);
    setWebsiteError(isValidUrl ? false : "Website URL is not valid");
  };

  const checkFirstName = (e) => {
    const firstNameValue = e.target.value;
    setFirstName(firstNameValue);
    if (firstNameValue.trim().length < 3) {
      setFirstNameError("Type at least 3 characters");
    } else {
      setFirstNameError(false);
    }
  };

  const checkLastName = (e) => {
    const lastNameValue = e.target.value;
    setLastName(lastNameValue);
    if (lastNameValue.trim().length < 3) {
      setLastNameError("Type at least 3 characters");
    } else {
      setLastNameError(false);
    }
  };

  const checkEmail = (e) => {
    setIsOTP(false);
    setSendOTP(false);
    let emailValue = e.target.value.trim();

    emailValue = emailValue.replace(/\s+/g, '');

    const emailReg = /^[\w+.-]+@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    const hasPlusBeforeAt =
      emailValue.includes("+") &&
      emailValue.indexOf("+") < emailValue.indexOf("@");
    setUserEmail(emailValue);

    if (!emailReg.test(emailValue)) {
      setUserEmailError("Enter a valid email address");
    } else if (hasPlusBeforeAt) {
      setUserEmailError("Aliases are not allowed");
      toast.error("Aliases are not allowed");
    } else {
      setUserEmailError(false);
      generateUUID();
    }
  };


  const checkNewPassword = (e) => {
    const newPasswordValue = e.target.value;
    const newPasswordValueTrim = newPasswordValue.trim();
    setNewPassword(newPasswordValueTrim);

    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

    const hasUppercase = uppercaseRegex.test(newPasswordValueTrim);
    const hasLowercase = lowercaseRegex.test(newPasswordValueTrim);
    const hasNumber = numberRegex.test(newPasswordValueTrim);
    const hasSpecialChar = specialCharRegex.test(newPasswordValueTrim);

    if (newPasswordValueTrim.length < 8) {
      setNewPasswordError("Password length should be minimum 8");
    } else if (!hasUppercase) {
      setNewPasswordError(
        "Password must include at least one uppercase letter"
      );
    } else if (!hasLowercase) {
      setNewPasswordError(
        "Password must include at least one lowercase letter"
      );
    } else if (!hasNumber) {
      setNewPasswordError("Password must include at least one number");
    } else if (!hasSpecialChar) {
      setNewPasswordError(
        "Password must include at least one special character"
      );
    } else {
      setNewPasswordError(false);
    }
  };

  const checkPhone = (e) => {
    let phoneValue = e.target.value;
    setPhone(phoneValue);

    if (phoneValue.trim().length > 0 && phoneValue.length != 10) {
      setPhoneError("Wrong Phone Number type");
    } else {
      setPhoneError(false);
    }
  };

  const handleRecaptchaChange = (value) => {
    setCaptchValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (accountType === "brand") {
      if (firstNameError !== false) {
        setFirstNameError("Please fill this field");
      } else if (userEmailError !== false) {
        setUserEmailError("Please fill this field");
      } else if (newPasswordError !== false) {
        setNewPasswordError("Incorrect Password type");
      } else if (phoneError !== false) {
        setPhoneError("Invalid Phone Number");
      } else if (website.trim().length > 0 && websiteError !== false) {
        setWebsiteError("Incorrect website name format");
      } else {
        if (isOTP === false) {
          checkPaymentStatus();
        } else {
          window.grecaptcha.execute();
        }
      }
    } else if (accountType === "seller") {
      // console.log(verifiedSeller.trim().length, "223");
      // console.log(sellerCodeError !== false);
      if (firstNameError !== false) {
        setFirstNameError("Please fill this field");
      } else if (userEmailError !== false) {
        setUserEmailError("Please fill this field");
      } else if (newPasswordError !== false) {
        setNewPasswordError("Incorrect Password type");
      } else if (sellerCodeError !== false) {
        setSellerCodeError("Invalid Seller ID");
      } else if (verifiedSeller.trim().length > 0) {
        // setSellerCodeError("Verify the seller ID to proceed");
        toast.error("Verify the Seller ID to proceed");
        // setSellerCodeError("");
      } else if (phoneError !== false) {
        setPhoneError("Invalid Phone Number");
      } else {
        if (isOTP === false) {
          checkPaymentStatus();
        } else {
          window.grecaptcha.execute();
        }
      }
    } else {
      if (firstNameError !== false) {
        setFirstNameError("Please fill this field");
      } else if (lastNameError !== false) {
        setLastNameError("Please fill this field");
      } else if (userEmailError !== false) {
        setUserEmailError("Please fill this field");
      } else if (newPasswordError !== false) {
        setNewPasswordError("Incorrect Password type");
      } else if (phoneError !== false) {
        setPhoneError("Invalid Phone Number");
      } else {
        if (isOTP === false) {
          checkPaymentStatus();
        } else {
          window.grecaptcha.execute();
        }
      }
    }
  };

  const OTPsubmit = (e) => {
    e.preventDefault();
    if (accountType !== "neither") {
      if (firstNameError !== false) {
        setFirstNameError("Please fill this field");
      } else if (userEmailError !== false) {
        setUserEmailError("Please fill this field");
      } else if (newPasswordError !== false) {
        setNewPasswordError("Incorrect Password type");
      } else if (phoneError !== false) {
        setPhoneError("Invalid Phonee Number");
      } else if (otpError !== false) {
        setOtpError("Incorrect OTP");
      } else {
        if (captchaValue?.length > 0) {
          signUpFirstApi();
        } else {
          window.grecaptcha.execute();
        }
      }
    } else {
      if (firstNameError !== false) {
        setFirstNameError("Please fill this field");
      } else if (lastNameError !== false) {
        setLastNameError("Please fill this field");
      } else if (userEmailError !== false) {
        setUserEmailError("Please fill this field");
      } else if (newPasswordError !== false) {
        setNewPasswordError("Incorrect Password type");
      } else if (phoneError !== false) {
        setPhoneError("Invalid Phone Number");
      } else if (otpError !== false) {
        setOtpError("Incorrect OTP");
      } else {
        if (captchaValue?.length > 0) {
          signUpFirstApi();
        } else {
          window.grecaptcha.execute();
        }
      }
    }
  };

  const signUpFirstApi = () => {
    setSignUpLoader(true);
    const sessionID = Cookies.get("UUID");
    const BrandformData = {
      brand_name: firstName,
      email: userEmail,
      phone_number: phone,
      uuid: sessionID,
      captcha: captchaValue,
      account_type: accountType,
      is_otp_sent: isOTP,
      ...(isOTP ? { otp: otpValue } : {}),
      brand_site: website,
      password: newPassword,
    };
    const SellerformData = {
      bussiness_name: firstName,
      email: userEmail,
      phone_number: phone,
      uuid: sessionID,
      captcha: captchaValue,
      seller_id: sellercode,
      seller_name: verifiedSeller,
      account_type: accountType,
      is_otp_sent: isOTP,
      ...(isOTP ? { otp: otpValue } : {}),
      bussiness_site: website,
      password: newPassword,
    };
    const OtherformData = {
      first_name: firstName,
      last_name: lastName,
      email: userEmail,
      phone_number: phone,
      uuid: sessionID,
      captcha: captchaValue,
      account_type: accountType,
      is_otp_sent: isOTP,
      ...(isOTP ? { otp: otpValue } : {}),
      password: newPassword,
    };

    let formData;

    if (accountType === "brand") {
      formData = BrandformData;
    } else if (accountType === "seller") {
      formData = SellerformData;
    } else {
      formData = OtherformData;
    }

    const api = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
    });

    api
      .post(`/api/signup`, formData)
      .then((response) => {
        const secretKey = "Klizo";
        const encrypteStep = CryptoJS.AES.encrypt(
          "StepOneDone",
          secretKey
        ).toString();
        Cookies.set("step", encrypteStep, { expires: 7 });
        Cookies.set("first_name", firstName, { expires: 7 });
        setTimeout(() => {
          window.location.reload();
        }, 100);
        setSignUpLoader(false);
      })
      .catch((error) => {
        setSignUpLoader(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const checkPaymentStatus = () => {
    const api = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
    });

    const mailData = {
      email: userEmail,
    };
    setSignUpLoader(true);
    api
      .post(`/api/check-payment-status`, mailData)
      .then((response) => {
        if (response?.data?.payment_status === true) {
          toast.error("This email id is already registered");
        } else if (
          response?.data?.payment_status === false &&
          response?.data?.otp_sent === true
        ) {
          toast.success(response?.data?.message);
          setOtpValue("");
          setSendOTP(true);
          setIsOTP(true);
        } else if (
          response?.data?.payment_status === false &&
          response?.data?.otp_sent === false
        ) {
          if (captchaValue !== null && captchaValue.length > 0) {
            signUpFirstApi();
            console.log("capcthaValue", captchaValue);
          } else {
            window.grecaptcha.execute();
            console.log("capcthaValue", captchaValue);
          }
        } else {
          toast.error("Something went wrong,try again later.");
        }
        setSignUpLoader(false);
      })
      .catch((error) => {
        setSignUpLoader(false);
        toast.error(error?.response?.data?.message);
        if (error?.response?.data?.otp_already_sent) {
          setOtpValue("");
          setSendOTP(true);
          setIsOTP(true);
        }
        // console.log("ccc", error?.response?.data?.otp_already_sent);
        // console.log("ccc", otpValue);
      });
    console.log("captcha value", captchaValue);
  };

  useEffect(() => {
    const paymentPage = Cookies.get("payment_page");
    if (paymentPage === "failure") {
      Cookies.remove("payment_page");
      Cookies.remove("step");
      Cookies.remove("uuid");
      navigate("/sign-up");
    }
    if (captchaValue?.length > 0 && !sellerCodeLoader) {
      signUpFirstApi();
    } else if (captchaValue?.length > 0 && sellerCodeLoader) {
      sellerVerifyAPI();
    }
    // }, [captchaValue]);
  }, [captchaValue]);

  const clickAccountType = () => {
    if (accountType.length > 0) {
      const label = document.querySelector(
        `label[for=${accountType}]`
      ).textContent;
      setSelectedAccountType(label);
    } else {
      setSelectedAccountType(false);
    }
    setFirstName("");
    setLastName("");
    setUserEmail("");
    setNewPassword("");
    setWebsite("");
    setPhone("");
    setOtpValue("");
    setSellercode("");
    setFirstNameError(null);
    setLastNameError(null);
    setUserEmailError(null);
    setNewPasswordError(null);
    setWebsiteError(null);
    setPhoneError(null);
    setPhoneError(null);
    setOtpError(null);
    setSellerCodeError(null);
    setSellerCodeLoader(false);
    setVerifiedSeller("");
  };
  const changeAccountType = () => {
    setSelectedAccountType(false);
  };
  const sellerverification = () => {
    setSellerCodeLoader(true);
    if (captchaValue?.length > 0) {
      // setSellerCodeLoader(false);
      sellerVerifyAPI();
    } else {
      window.grecaptcha.execute();
    }
  };
  const sellerVerifyAPI = () => {
    // setSellerCodeLoader(true);
    const api = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
    });
    const sessionID = Cookies.get("UUID");
    const sellerData = {
      uuid: sessionID,
      seller_id: sellercode,
      captcha: captchaValue,
    };
    // setTimeout(() => {
    api
      .post(`/api/seller-id-verification`, sellerData)
      .then((response) => {
        // console.log("483", response);
        // console.log("489", response?.data?.data);
        toast.success(response?.data?.message);
        setSellerCodeError("");
        setVerifiedSeller(response?.data?.data);
        setSellerCodeError(false);
        setSellerCodeLoader(false);
        setSellerInterval(true);
        setTimeout(() => {
          setSellerInterval(false);
        }, 10000);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setSellerCodeLoader(false);
        setSellerInterval(true);
        if (error?.response?.data?.status == false) {
          setVerifiedSeller("");
          setSellerCodeError(error?.response?.data?.message);
        } 
        setTimeout(() => {
          setSellerInterval(false);
        }, 10000);
      });
    // }, 40000);
  };
  return (
    <div className="signUpFlow py-5">
      <ParticleBg />
      <div className="container position-relative pt-5">
        <div className="row g-4 g-xl-5 align-items-center justify-content-center">
          {/* <div className="col-12 mt-lg-0">
            <div className="row justify-content-center">

              <div className="col-lg-6 col-xl-7 col-xxl-7 ms-auto">
                <div className="offer_bar sign_up">
                    <h5>Get $50 off at sign-up, use code <span className="shadow">Nformed50</span></h5>
                </div>
              </div>

            </div>
          </div> */}
          <div className="col-lg-6 col-xxl-5 col-xl-5">
              <div className="">
                <img src={logo} alt="" className="img-fluid spLogo" />
              </div>
            <div className="signUpLeft">
              <h5>Create Account</h5>
              <p className="">
                You're just a few clicks away from your next winning Amazon product. We're excited for you!
              </p>
              <Form onSubmit={handleSubmit}>
                {!selectedAccountType && (
                  <div className="row g-2 mb-3 will_hide">
                    <div className="col-12">
                      <h2 className="fw-bold fs-2 mt-3">You are</h2>
                    </div>
                    <div className="col-12">
                      <Form.Check>
                        <Form.Check.Input
                          type={"radio"}
                          id={`brand`}
                          name="accountType"
                          defaultChecked={accountType === "brand"}
                          onChange={() => setAccountType("brand")}
                        />
                        <Form.Check.Label
                          htmlFor="brand"
                          // onMouseOver={() => setAccountType("brand")}
                        >
                          <img
                            src={brandIcon}
                            className="img-fluid me-3"
                            alt="Brand/Organization"
                          />
                          Brand/Organization
                        </Form.Check.Label>
                      </Form.Check>
                    </div>
                    <div className="col-12">
                      <Form.Check>
                        <Form.Check.Input
                          type={"radio"}
                          id={`seller`}
                          name="accountType"
                          defaultChecked={accountType === "seller"}
                          onChange={() => setAccountType("seller")}
                        />
                        <Form.Check.Label htmlFor="seller">
                          <img
                            src={sellerIcon}
                            className="img-fluid me-3"
                            alt="Seller/Independent manufacturer"
                          />
                          Seller/Independent Manufacturer
                        </Form.Check.Label>
                      </Form.Check>
                    </div>
                    <div className="col-12">
                      <Form.Check>
                        <Form.Check.Input
                          type={"radio"}
                          id={`neither`}
                          name="accountType"
                          defaultChecked={accountType === "neither"}
                          onChange={() => setAccountType("neither")}
                        />
                        <Form.Check.Label htmlFor="neither">
                          <img
                            src={neitherIcon}
                            className="img-fluid me-3"
                            alt="neither"
                          />
                          I'm an Enthusiast
                        </Form.Check.Label>
                      </Form.Check>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-main2  w-100 mt-4"
                        type="buton"
                        disabled={accountType.length > 0 ? false : true}
                        onClick={clickAccountType}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                )}

                {accountType.length > 0 && selectedAccountType && (
                  <>
                    {/* <div className="selectedAccount">
                      <p>You are:</p>
                      <div className="row g-3 pt-1">
                        <div className="col-auto me-auto">
                          <h6 className="text-capitalize">
                            {accountType.length > 0 ? selectedAccountType : ""}
                          </h6>
                        </div>
                        <div className="col-auto">
                          <a
                            type="button"
                            onClick={changeAccountType}
                            className="btn p-0 border-0 text-primary"
                            style={{ textDecoration: "underline" }}
                          >
                            Change
                          </a>
                        </div>
                      </div>
                    </div> */}
                    <Form.Group className="mb-4" controlId="firstName">
                      <Form.Label>
                        {accountType === "brand" && "Brand/Organization"}
                        {accountType === "seller" && "Name"}
                        {accountType === "neither" && "First Name"}
                      </Form.Label>
                      <div className="position-relative custom-field">
                        <i className="bi bi-person-fill position-absolute start-0 h-100 pe-1 d-flex align-items-center fs-5"></i>
                        <Form.Control
                          className={firstNameError ? "error ps-4" : "ps-4"}
                          type="text"
                          placeholder={`${
                            accountType === "brand"
                              ? "Brand/Organization"
                              : accountType === "seller"
                              ? "First and last name"
                              : accountType === "neither"
                              ? "First Name"
                              : "First Name"
                          }`}
                          value={firstName}
                          onChange={checkFirstName}
                        />
                      </div>
                      {firstNameError && (
                        <small className="text-danger">{firstNameError}</small>
                      )}
                    </Form.Group>
                    {accountType === "neither" && (
                      <Form.Group className="mb-4" controlId="lastName">
                        <Form.Label>Last Name</Form.Label>
                        <div className="position-relative custom-field">
                          <i className="bi bi-person-fill position-absolute start-0 h-100 pe-1 d-flex align-items-center fs-5"></i>
                          <Form.Control
                            className={lastNameError ? "error ps-4" : "ps-4"}
                            type="text"
                            placeholder="Enter your last name"
                            value={lastName}
                            onChange={checkLastName}
                          />
                        </div>
                        {lastNameError && (
                          <small className="text-danger">{lastNameError}</small>
                        )}
                      </Form.Group>
                    )}

                    <Form.Group className="mb-4" controlId="email">
                      <Form.Label>
                        {accountType === "brand" && "Brand/Organization Email"}
                        {accountType === "seller" && "Email"}
                        {accountType === "neither" && "Email"}
                      </Form.Label>
                      <div className="position-relative custom-field">
                        <i className="bi bi-envelope-fill position-absolute start-0 h-100 pe-1 d-flex align-items-center"></i>
                        <Form.Control
                          className={userEmailError ? "error ps-4" : "ps-4"}
                          type="email"
                          autoComplete="username"
                          placeholder={` ${
                            accountType === "brand"
                              ? "Brand/Organization Email"
                              : accountType === "seller"
                              ? "This will be your login"
                              : accountType === "neither"
                              ? "Email"
                              : "Email"
                          }`}
                          value={userEmail}
                          onChange={checkEmail}
                        />
                      </div>
                      {userEmailError && (
                        <small className="text-danger">{userEmailError}</small>
                      )}
                    </Form.Group>

                    {sendOTP === true && (
                      <Form.Group className="mb-4" controlId="verifyOTP">
                        <Form.Label>OTP</Form.Label>
                        <div className="position-relative custom-field">
                          <i className="bi bi-shield-fill position-absolute start-0 h-100 pe-1 d-flex align-items-center"></i>
                          <Form.Control
                            type="number"
                            placeholder="Enter Your 6 digit OTP"
                            className="ps-4"
                            value={otpValue}
                            onChange={checkOtp}
                            // onKeyPress={handleKeyDown}
                            onKeyDown={(event) => {
                              if (
                                ["e", ".", "+", "-"].includes(event.key) ||
                                event.key === "ArrowUp" ||
                                event.key === "ArrowDown"
                              ) {
                                event.preventDefault();
                              }
                            }}
                            onWheel={(e) => e.target.blur()}
                          />
                        </div>
                        {otpError && (
                          <small className="text-danger">{otpError}</small>
                        )}
                      </Form.Group>
                    )}

                    <Form.Group className="mb-4" controlId="newPassword">
                      <Form.Label>Password</Form.Label>
                      <div className="position-relative custom-field">
                        <i className="bi bi-lock-fill position-absolute start-0 h-100 pe-1 d-flex align-items-center"></i>
                        <Form.Control
                          className={
                            newPasswordError ? "error ps-4 pe-5" : "ps-4 pe-5"
                          }
                          type={viewPass1 ? "text" : "password"}
                          placeholder="choose a strong password"
                          value={newPassword}
                          onChange={checkNewPassword}
                          autoComplete="current-password"
                        />
                        <button
                          type="button"
                          className="btn border-0 position-absolute end-0 top-0 h-100 text-dark"
                          onClick={() => setViewPasss1(!viewPass1)}
                        >
                          {viewPass1 ? (
                            <i className="bi bi-eye-slash"></i>
                          ) : (
                            <i className="bi bi-eye"></i>
                          )}
                        </button>
                      </div>
                      {newPasswordError && (
                        <small className="text-danger">
                          {newPasswordError}
                        </small>
                      )}
                    </Form.Group>
                    {accountType === "seller" && (
                      <>
                        <Form.Group className="mb-4 d-none" controlId="seller_ID">
                          <Form.Label>Seller ID (optional)</Form.Label>
                          <div className="position-relative custom-field">
                            <i className="bi bi-telephone-fill position-absolute start-0 h-100 pe-1 d-flex align-items-center"></i>
                            <Form.Control
                              className={
                                sellerCodeError ? "error ps-4" : "ps-4"
                              }
                              // className="ps-4"
                              type="text"
                              style={{ paddingRight: "80px" }}
                              placeholder="Enter your Seller ID"
                              value={sellercode}
                              onChange={checkSellerID}
                              disabled={sellerInterval ? true : false}
                              // onKeyPress={handleKeyDown}
                            />
                            {sellercode?.trim().length > 0 && (
                              <button
                                type="button"
                                className="btn btn-main2  px-3 py-1 position-absolute end-0 top-0 mt-1"
                                style={{ fontSize: "12px" }}
                                disabled={
                                  sellerCodeError || sellerCodeLoader || sellerInterval
                                    ? true
                                    : false
                                }
                                onClick={sellerverification}
                              >
                                {sellerInterval ? <Timer /> : "Verify"}
                                {/* Verify */}
                                {sellerCodeLoader && (
                                  <i className="fad fa-spinner fa-pulse fa-xs ms-1"></i>
                                )}
                              </button>
                            )}
                          </div>
                          {sellerCodeError && (
                            <small className="text-danger">
                              {sellerCodeError}
                            </small>
                          )}
                          {verifiedSeller && (
                            <small className="text-success">
                              {verifiedSeller}
                            </small>
                          )}
                        </Form.Group>
                      </>
                    )}
                    {accountType !== "neither" && (
                      <Form.Group className="mb-4 d-none" controlId="CompanyWebsite">
                        <Form.Label>
                          {accountType === "brand"
                            ? "Brand/org website(optional)"
                            : accountType === "seller"
                            ? "Business Website (optional)"
                            : "website(optional)"}
                        </Form.Label>
                        <div className="position-relative custom-field">
                          <i className="bi bi-globe-americas position-absolute start-0 h-100 pe-1 d-flex align-items-center"></i>
                          <Form.Control
                            className={
                              websiteError && website.trim().length > 0
                                ? "error ps-4"
                                : "ps-4"
                            }
                            type="text"
                            placeholder={`Enter your ${
                              accountType === "brand"
                                ? "Brand/org website"
                                : accountType === "seller"
                                ? "Business Website"
                                : "website"
                            }`}
                            value={website}
                            onChange={checkWebsite}
                          />
                        </div>
                        {websiteError && website.trim().length > 0 && (
                          <small className="text-danger">{websiteError}</small>
                        )}
                      </Form.Group>
                    )}
                    <Form.Group className="mb-4" controlId="phone">
                      <Form.Label>Phone Number (optional)</Form.Label>
                      <div className="position-relative custom-field">
                        <i className="bi bi-telephone-fill position-absolute start-0 h-100 pe-1 d-flex align-items-center"></i>
                        <Form.Control
                          className={phoneError ? "error ps-4" : "ps-4"}
                          type="number"
                          placeholder="Enter your Phone Number"
                          value={phone}
                          onChange={checkPhone}
                          // onKeyPress={handleKeyDown}
                          onKeyDown={(event) => {
                            if (
                              ["e", ".", "+", "-"].includes(event.key) ||
                              event.key === "ArrowUp" ||
                              event.key === "ArrowDown"
                            ) {
                              event.preventDefault();
                            }
                          }}
                          onWheel={(e) => e.target.blur()}
                        />
                      </div>
                      {phoneError && (
                        <small className="text-danger">{phoneError}</small>
                      )}
                    </Form.Group>

                    <ReCAPTCHA
                      sitekey="6LeQVFYpAAAAABj2fVpd9l9gJnSkugoaRgqYONv0"
                      onChange={handleRecaptchaChange}
                      size="invisible"
                    />
                    {sendOTP ? (
                      <Button
                        className="btn-main2  w-100 mt-3"
                        variant="transparent"
                        type="submit"
                        onClick={OTPsubmit}
                        // onClick={() => window.grecaptcha.execute()}
                        disabled={signUpLoader ? true : false}
                      >
                        Continue
                        {signUpLoader && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="ms-2"
                          />
                        )}
                      </Button>
                    ) : (
                      <Button
                        className="btn-main2  w-100 mt-3"
                        variant="transparent"
                        type="submit"
                        onClick={handleSubmit}
                        // onClick={() => window.grecaptcha.execute()}
                        disabled={signUpLoader ? true : false}
                      >
                        Sign Up
                        {signUpLoader && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="ms-2"
                          />
                        )}
                      </Button>
                    )}
                  </>
                )}
              </Form>
            </div>
          </div>

          {/* <div className="col-lg-6 col-xl-7 col-xxl-7">
            {accountType.length > 0 ? (
              accountType === "brand" ? (
                <SignUpRightBrand />
              ) : accountType === "seller" ? (
                <SignUpRightSeller />
              ) : (
                <SignUpNeither />
              )
            ) : (
              <SignUpRight />
            )}
          </div> */}

        </div>
      </div>
    </div>
  );
};

export default SignUpNew;
