import React, { useEffect, useState } from 'react'
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
// import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter } from 'react-icons/bs';
import axios from 'axios';
import Logo from '../../Assets/LogoBlack.png';
import './Footer.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Toast from 'react-bootstrap/Toast';
import BookFreeDemo from '../modal/BookFreeDemo';

function Footer() {
    const [modalShow, setModalShow] = React.useState(false);
    const [subData, setSubData] = useState("");
    const [formError, setFormError] = useState('false');
    const [isOpen, setIsOpen] = useState(false);
    const openc = () => !isOpen;
    const subscribe = async () => {
        
        setTimeout(()=>{
            setIsOpen(false);
        },2000)
        const regex = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{1,})$/i;
        if(!subData) {
            // console.log("1 if");
            setFormError(false);
            setIsOpen(true); 
        }else if(regex.test(subData) === false){
            
            setFormError(false);
            setIsOpen(true);
        } else{
            
            setFormError(true);
            setIsOpen(false);
            await axios.post(`${process.env.REACT_APP_BASE_URL}/subscribe`, {
            email: subData,
        }).then((response)=>{
            
            setFormError(true);
            setIsOpen(true);
        })
            .catch(function (error) {
                // console.log(error);
            });
        }
        
    }

    const emailvalue = async (data) => {
        setSubData(data); 
        
    }
    useEffect(() => {
       

    }, [subData, formError]);
    return (
        <div>
            <Container id='footer'>
                <Row className='position-relative'>
                    <Col xs={12} md={3}>
                        <NavLink to='/'>
                        <div className="logo">
                            <img src={Logo} alt="" className='img-fluid'/>
                        </div>
                        </NavLink>
                        {/* <div className="socialIcon">
                            <ul>
                                <li style={{ marginLeft: '0px' }}><BsFacebook /></li>
                                <li><BsLinkedin /></li>
                                <li><BsTwitter /></li>
                                <li><BsInstagram /></li>
                            </ul>
                        </div> */}
                    </Col>
                    <Col xs={12} md={3}>
                        <div className="QuickLinks">
                            <div className="qheading">
                                <h3>Quick Links</h3>
                            </div>
                            <div className="QuickLinksItems">
                                <ul>
                                    <li><Nav.Link href="/#onlinesalesdata">Data Analytics</Nav.Link></li>
                                    <li><Nav.Link href="/#salestracking">Track Sales</Nav.Link></li>
                                    {/* <li><NavLink>About us</NavLink></li> */}
                                    <li onClick={() => setModalShow(true)}><NavLink>Book a Free Demo</NavLink></li>
                                    <li><Nav.Link href="/#saler_dashboard">For Sellers</Nav.Link></li>
                                    <li><Nav.Link href="/#brand_dashboard">For Brands</Nav.Link></li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={3}>
                        <div className="LegalStuff">
                            <div className="LegalStuffHeading">
                                <h3>Legal Stuff</h3>
                            </div>
                            <div className="LegalStuffItems">
                                <ul>
                                    <li>
                                        <NavLink to="/privacy"> Privacy Policy</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/terms">Terms & Conditions</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/copyright"> Copyright Policy</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={3}>
                        <div className="Newsletter">
                            <div className="newslaterHeading">
                                <h3 style={{fontWeight: '600',fontFamily: 'Montserrat',fontSize:'22px'}}>
                                    Sign Up Our Newsletter
                                </h3>
                            </div>
                            <Toast show={isOpen} onClose={openc} bg={!formError ? 'danger' : "Success"} >
                                <Toast.Header closeButton={false}>
                                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                                    <strong className="me-auto">{!formError ? "Error" : "Success"}</strong>
                                </Toast.Header>
                                <Toast.Body className={!formError ? 'danger' : "success"}>{!formError ? "Please Enter A Valid EmailID" : "Subscribe Successfully"}</Toast.Body>
                            </Toast>
                            <div className="newslaterForm mt-5">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Email address..."
                                        aria-label="Email"
                                        aria-describedby="Email"
                                        bsPrefix="cdesign"
                                        value={subData}
                                        onChange={(e) => emailvalue(e.target.value)}
                                        className="text-white"
                                    />
                                    <Button variant="outline-secondary" onClick={subscribe} className='dButton' id="button-addon2" style={{ backgroundColor: "#009A59" }}>
                                        Subscribe
                                    </Button>
                                </InputGroup>
                            </div>
                        </div>
                    </Col>

                    <hr style={{ color: "#8A8A8A", margin: "40px 0px" }} />

                    <p className='text-center ftexty'>Copyright ©2023 nformed. All rights reserved.</p>

                </Row>
                <BookFreeDemo show={modalShow} onHide={() => setModalShow(false)} />
            </Container>
        </div>
    )
}

export default Footer;
