import { useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { FaChevronCircleDown } from 'react-icons/fa';
import './style.css'
function Acordion(props) {

  useEffect(()=>{
    // console.log(props.data);
  })
  return (
    <Accordion defaultActiveKey={['0']}>
      {
       props.data? props.data.map((scordionItem,key) => (
          <Accordion.Item eventKey={key}>
          <Accordion.Header className='bg-dark text-white'><span className='me-2 '>{key+1}. {"  "}</span>{scordionItem.title} <span className='acc_icon'><FaChevronCircleDown/></span></Accordion.Header>
          <Accordion.Body className=''>
          {scordionItem.body}
          </Accordion.Body>
          </Accordion.Item>
        )) : null
      }
    </Accordion>
  );
}

export default Acordion;