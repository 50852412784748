import React, { useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LogoIcon from '../../Assets/LogoBlack.png';
import NotificationIcon from '../../Assets/Icon.png';
import { Button } from 'react-bootstrap';
import {FaBars} from 'react-icons/fa';
import {ImCross} from 'react-icons/im';
import 'animate.css';
import './navbar.css';
import { Link, NavLink } from 'react-router-dom';
import { MdOutlineDashboard } from "react-icons/md";

function NewNavbar() {
    const [isOpen,Setisopen] = useState(false);
    // useEffect(() => {
    //     const href = window.location.href.substring(
    //       window.location.href.lastIndexOf('#') + 1
    //     );
    //     const element = document.getElementById(href);
    //     if (element) {
    //       element.scrollIntoView({ behavior: 'smooth' });
    //     }
    //   }, []);
    return (
        <>
            <div>
                <Navbar className='navbar_main bg-white position-static'  expand="lg">
                    <Container className='d-block '>
                        <Row className='align-items-center g-2'>
                            <Col xs={'auto'} className='me-auto'>
                                <div className="logo d-flex">
                                <NavLink to='/' className="logoLink"> <img src={LogoIcon} alt="Logo" className='img-fluid' style={{"margin": "20px 0px "}}/></NavLink>
                                </div>
                            </Col>
                            <Col xs={'auto'} className="d-lg-flex d-none me-auto">
                                <div className="buttons_group d-flex">
                                    <ul className="link_button d-flex" style={{"listStyle":"none","margin":"20px 0px","fontSize":"14px", "alignItems":"center"}}>
                                        <li className='upload_csv'><Nav.Link  href='/#product_overview'>Product Overview</Nav.Link></li>
                                        <li className='upload_csv'><Nav.Link  href='/#why_nformed'>Why Nformed?</Nav.Link></li>
                                        <li className='dataAnatics upload_csv'>
                                            <Nav.Link href="/#testimonial">Testimonials</Nav.Link>
                                        </li>
                                        <li className='upload_csv'><Nav.Link  href='/#pricing'>Pricing</Nav.Link></li>
                                    </ul>
                                    
                                </div>
                            </Col>
                            <Col xs="auto">
                                <div className="dash_button ms-0">
                                        <Button variant="transparent" href={process.env.REACT_APP_BACKEND_URL} className='btn nformed-btn sign-in-btn'>
                                            <span className='d-none d-lg-inline'>Sign In</span>
                                            <span className='d-inline d-lg-none'><MdOutlineDashboard /></span>
                                        </Button>
                                </div>
                            </Col>
                            {/* <Col xs="auto">
                                <div className="bell_icon ms-0">
                                    <img src={NotificationIcon} alt="Notification Icon" className='bellimg'/>
                                </div>
                            </Col> */}
                            <Col xs={'auto'} className='d-lg-none d-flex'>
                                <div className="logo d-flex">
                                {
                                    !isOpen ? <FaBars onClick={(e)=>Setisopen(true)}/> : null
                                }
                                </div>
                            </Col>
                            {
                            <div className={`sidebar ${isOpen ? 'active' : null}`}>
                                    <div className="sidebarlogo">
                                    <img src={LogoIcon} alt="Logo"/>
                                    <ImCross className='sidebarCross' onClick={(e)=>Setisopen(false)}/>
                                    </div>
                            

                                <hr />
                                <ul className='menuCon'>
                                    <li className='menuItem' onClick={(e)=>Setisopen(false)}><NavLink href="#product_overview" className='text-decoration-none text-dark'>Product Overview</NavLink></li>
                                    <li className='menuItem' onClick={(e)=>Setisopen(false)}><Nav.Link href='#why_nformed' >Why Nformed?</Nav.Link></li>
                                    <li className='menuItem' onClick={(e)=>Setisopen(false)}><Nav.Link href='#testimonial'>Testimonials</Nav.Link></li>
                                    <li className='menuItem' onClick={(e)=>Setisopen(false)}><Nav.Link href='#pricing'>Pricing</Nav.Link></li>
                                </ul>
                            </div>
                            }
                        </Row>
                    </Container>
                </Navbar>
            </div>
        </>
    )
}

export default NewNavbar
