import React, { useState, useRef } from 'react'
import { Col, Container, Nav, Row } from 'react-bootstrap'
import LinkedInicon from '../Icons/LinkedInicon'
import { Link } from 'react-router-dom'
import XIcon from '../Icons/XIcon'
import TubeIocn from '../Icons/TubeIocn'
import footerLogo from '../Assets/GroupLogo9086.png'
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import axios from 'axios'
import Spinner from 'react-bootstrap/Spinner';

const NewFooter = () => {
    const [captchaValue, setCaptchValue] = useState("");
    const [newsLetterEmail, setNewsLetterEmail] = useState('');
    const [newsLetterLoader, setNewsLetterLoader] = useState(false);
    const recaptcha = useRef()


    const submitNewsLetter = (token) => {
        const api = axios.create({
          baseURL: process.env.REACT_APP_BACKEND_URL,
        });
    
        const mailData = {
            newsletter_email  : newsLetterEmail,
            captcha : token,
        };
        api
          .post(`/api/subscribe-newsletter`, mailData)
          .then((response) => {
            // console.log(response);
            setNewsLetterLoader(false);
            toast.success(response?.data?.message ? response?.data?.message :  'Successfully Subscribed');
            // setCaptchValue('');
            setTimeout(()=>{
                window.location.reload()
            },5000)
            
          })
          .catch((error) => {
            console.log(error);
            // console.log(error?.message);
            setNewsLetterLoader(false);
            toast.error(error?.response?.data?.message ? error?.response?.data?.message : 'Something went wrong');
            // setCaptchValue('');
            setTimeout(()=>{
                window.location.reload()
            },5000)
          });
        // console.log("captcha value", captchaValue);
      };

    const executeSubscribe = async () => {
        const captchaValueToken = await recaptcha.current.executeAsync()
        if (captchaValueToken) {
            // console.log(captchaValueToken)
            setCaptchValue(captchaValueToken);
            submitNewsLetter(captchaValueToken);
            setNewsLetterLoader(true);
        } else {
            toast.error('captcha verification failed');
            setNewsLetterLoader(false);
        }
    }

    const SubscribeNewsLetter = () => {

        const emailReg = /^[\w+.-]+@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        const hasPlusBeforeAt =
        newsLetterEmail.includes("+") &&
        newsLetterEmail.indexOf("+") < newsLetterEmail.indexOf("@");

        if (!emailReg.test(newsLetterEmail)) {
        toast.error("Enter a valid email address");
        } else if (hasPlusBeforeAt) {
        toast.error("Aliases are not allowed");
        } else {

            // if(captchaValue.length > 0 && captchaValue !== null){
            //     toast.info("Too many attempts, try agian after 2 minutes");
            // }
            // else{
            // }
            executeSubscribe();
        }
    }
  return (
    <div className='NewFooter py-5'>
        <Container className='py-lg-5'>
            <Row className='g-4 g-lg-3  justify-content-lg-between'>
                <Col xs="auto">
                    <div className="NewFooterBlock ">
                        <img src={footerLogo} alt="" className="img-fluid mb-lg-5" />
                        <div className='nfbLFotter mt-5'>
                            <p className='mb-2'>Follow us on: <Link to="https://www.linkedin.com/company/nformed/" target='_blank' className='ms-1 fs-4'><LinkedInicon/></Link></p>
                            {/* <div className="scicon">
                                <Link to="#"><LinkedInicon/></Link>
                                <Link to="#"><XIcon/></Link>
                                <Link to="#"><TubeIocn/></Link>
                            </div> */}
                        </div>
                    </div>
                </Col>
                <Col xs="auto">
                    <p>Quick Links</p>
                    <ul>
                        {/* <li>
                            <Link to="/">Data Analytics</Link>
                        </li>
                        <li>
                            <Link to="/">Track Sales</Link>
                        </li>
                        <li>
                            <Link to="/">Book a Free Demo</Link>
                        </li>
                        <li>
                            <Link to="/">For Sellers</Link>
                        </li>
                        <li>
                            <Link to="/">For Brands</Link>
                        </li> */}
                        <li className='upload_csv'><Nav.Link  href='/#product_overview'>Product Overview</Nav.Link></li>
                        <li className='upload_csv'><Nav.Link  href='/#why_nformed'>Why Nformed?</Nav.Link></li>
                        <li className='dataAnatics upload_csv'>
                            <Nav.Link href="/#testimonial">Testimonials</Nav.Link>
                        </li>
                        <li className='upload_csv'><Nav.Link  href='/#pricing'>Pricing</Nav.Link></li>
                    </ul>
                </Col>

                {/* <Col xs="auto">
                    <p>Header</p>
                    <ul>
                        <li>
                            <Link to="/privacy">Privacy Policy</Link>
                        </li>
                        <li>
                            <Link to="/terms">Terms & Conditions</Link>
                        </li>
                        <li>
                            <Link to="/">Copyright Policy</Link>
                        </li>
                    </ul>
                </Col> */}

                <Col xs="auto">
                    <div className="newsLetter">
                        <h3>News Letter</h3>
                        <div className="newsLetterBlock">
                            <input type="email" className='form-control' placeholder='Enter Email' value={newsLetterEmail} onChange={(e)=>setNewsLetterEmail(e.target.value)} />
                            <button className='btn nformed-btn sign-in-btn' disabled={newsLetterLoader ? true : false} onClick={SubscribeNewsLetter}>
                                {newsLetterLoader ? 
                                    <>
                                    <span>Wait.. </span> 
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    </>
                                    :
                                    <span>Subscribe </span> 
                                }
                            </button>
                            <ReCAPTCHA
                                sitekey="6LeQVFYpAAAAABj2fVpd9l9gJnSkugoaRgqYONv0"
                                // onChange={handleRecaptchaChange}
                                size="invisible"
                                ref={recaptcha}
                            />
                        </div>
                        {/* <Link to="/sign-up" className='btn nformed-btn'>Get Started</Link> */}
                    </div>
                </Col>
                {/* <Col xs="auto">
                    <h3>Let’s Simply Sales</h3>
                    <Link to="/sign-up" className='btn nformed-btn'>Get Started</Link>
                </Col> */}
            </Row>
            <div className="text-lg-center pt-5 mt-lg-5">
                <p>Copyright ©2023-2024 nformed. All rights reserved.</p>
                <p className='copyrightLinks'><Link to="/privacy">Privacy Policy</Link> | <Link to="/terms">Terms & Conditions</Link> | <Link to="/">Copyright Policy</Link></p>
            </div>
        </Container>
    </div>
  )
}

export default NewFooter