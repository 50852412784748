import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import averagePriceChart from '../Assets/new-landing-page-images/average_pricew_chart.png'
import oldWayImage from  '../Assets/new-landing-page-images/old_way.png'
import newWayImage from '../Assets/new-landing-page-images/new_way.png'
import CrossUlIcon from '../Icons/CrossUlIcon'
import CheckULIcon from '../Icons/CheckULIcon'
import { Link } from 'react-router-dom'
import { Parallax } from 'react-scroll-parallax';
const OldAndNew = () => {
  return (
    <div className='oldAndNew'>
        <Container fluid className='px-3 px-lg-4 px-xl-5'>
            <Row className='g-0'>
                <Col lg="4">
                    <div className="oldAndNewChild oanLeft py-5 pe-lg-5 pe-4 ps-4 ps-lg-0">
                        <div className="py-lg-5">
                            <img src={oldWayImage} alt="" className="img-fluid smallImage" />
                            <h2>The old way</h2>
                            <span className='pdsbadge my-2'>Manually Research Products</span>
                            <ul>
                                <li> <span><CrossUlIcon/></span> Hours spent gathering data</li>
                                <li> <span><CrossUlIcon/></span> Incomplete competitor insights</li>
                                <li><span><CrossUlIcon/></span> Missed opportunities due to outdated information</li>
                                <li><span><CrossUlIcon/></span> Fragmented tools for research and tracking</li>
                            </ul>
                        </div>
                    </div>
                </Col>
                <Col lg="8">
                    <div className="oldAndNewChild oanRight py-5 p-4 p-lg-5">
                        <div className="py-lg-5">
                            <Row className='align-items-center'>
                                <Col lg="6" xl="6" xxl="5">
                                    <img src={newWayImage} alt="" className="img-fluid smallImage" />
                                    <h2>
                                        The new way  <span className='versus'>VS</span>
                                    </h2>
                                    <span className='pdsbadge bg-white my-2'>Leverage Nformed</span>
                                    <ul>
                                        <li><span><CheckULIcon/></span>Centralized data for quick research</li>
                                        <li><span><CheckULIcon/></span>Comprehensive competitor analysis</li>
                                        <li><span><CheckULIcon/></span>Real-time updates on market trends</li>
                                        <li><span><CheckULIcon/></span>All-in-one tool for efficiency</li>
                                    </ul>
                                    <div className="mt-4 mt-lg-5 oanRightFooter">
                                        {/* <button className='btn nformed-btn'>Try Nformed Today</button> */}
                                        <Link to="/sign-up" className='btn nformed-btn' >Try Nformed Today</Link>
                                        <p> Start your free trial. Cancel anytime.</p>
                                    </div>
                                </Col>
                                <Col lg="6" xl="6" xxl="7">
                                    <Parallax  translateX={[0, 15]}>
                                        <img src={averagePriceChart} alt="" className="img-fluid" />
                                    </Parallax>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default OldAndNew