import React from "react";
import photo from '../../../Assets/auth_photo.png'

const SignUpRight = () => {
  return (
    <div className="signUpright surcL px-4 px-lg-5 py-3 d-none d-lg-flex">
      <img src={photo} alt="" className="img-fluid" />
        <div className="content">
            <h3>Sign up to run reports.</h3>
            <p>Data reporting that gives real insights.</p>
        </div>
    </div>
  );
};

export default SignUpRight;
