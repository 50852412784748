import React, { useEffect, useState } from "react";
import SignUpRight from "./common/SignUpRight";
import "./signup.scss";
import logo from "../../Assets/LogoBlack.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "js-cookie";
import axios from "axios";

const SellerConfirmation = () => {
  const [sellerValue, setSellerValue] = useState("yes");

  const [sellerCompanyName, setSellerCompanyName] = useState("");
  const [scnError, setScnError] = useState(null);

  const [website, setWebsite] = useState("");
  const [websiteError, setWebsiteError] = useState(null);

  const [sellerID, setSellerID] = useState("");
  const [sellerIDerror, setSellerIDerror] = useState(null);

  const [continueLoader, setContinueLoader] = useState(false);

  const [captchaValue, setCaptchValue] = useState("");

  const checkSellerCompanyName = (e) => {
    const scnValue = e.target.value;
    setSellerCompanyName(scnValue);
    if (scnValue.trim().length < 3) {
      setScnError("Type at least 3 characters");
    } else {
      setScnError(false);
    }
  };

  const checkWebsite = (e) => {
    const url = e.target.value;
    setWebsite(url);
    const urlRegex =
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?:(?!-)[A-Z\d-]{1,63}(?<!-)\.?)+[A-Z]{2,63}|localhost|\d{1,3}(?:\.\d{1,3}){3})(?::\d{1,5})?(?:[/?#]\S*)?$/i;
    const isValidUrl = urlRegex.test(url);
    setWebsiteError(isValidUrl ? false : "Please enter a valid website URL");
  };

  const checkSellerID = (e) => {
    const sIDvalue = e.target.value;
    setSellerID(sIDvalue);
    if (sIDvalue.trim().length < 12) {
      setSellerIDerror("Enter valid Seller ID");
    } else {
      setSellerIDerror(false);
    }
  };

  const navigate = useNavigate();

  const continueSeller = (e) => {
    e.preventDefault();
    // setContinueLoader(true);
    if (
      sellerValue == "yes" &&
      scnError === false &&
      websiteError === false &&
      sellerIDerror === false
    ) {
      window.grecaptcha.execute();
    } else if (sellerValue == "no") {
      window.grecaptcha.execute();
    } else {
      toast.error("Please fill all the details to continue");
      setContinueLoader(false);
    }
    // console.log(captchaValue);
  };

  const handleRecaptchaChange = (value) => {
    setCaptchValue(value);
  };

  const hitNextPlan = () => {
    // console.log(captchaValue);
    setContinueLoader(true);
    const api = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
    });
    const sessionID = Cookies.get("UUID");

    if (
      sellerValue == "yes" &&
      scnError === false &&
      websiteError === false &&
      sellerIDerror === false
    ) {
      const sellerDeatils = {
        company_name: sellerCompanyName,
        is_seller: true,
        company_website: website,
        amazon_seller_id: sellerID,
        captcha: captchaValue,
        uuid: sessionID,
      };
      api
        .post(`/api/store-seller-info`, sellerDeatils)
        .then((response) => {
          // console.log(response);
          setContinueLoader(false);
          toast.success(response?.data?.message);
          Cookies.set("step", "StepThreeDone", { expires: 7 });
          setTimeout(() => {
            navigate("/membership");
            window.location.reload();
          }, 100);
        })
        .catch((error) => {
          setContinueLoader(false);
          // console.log(error);
        });
    } else if (sellerValue == "no") {
      const sellerDeatils = {
        company_name: "",
        is_seller: false,
        company_website: "",
        amazon_seller_id: "",
        captcha: captchaValue,
        uuid: sessionID,
      };
      api
        .post(`/api/store-seller-info`, sellerDeatils)
        .then((response) => {
          // console.log(response);
          setContinueLoader(false);
          toast.success(response?.data?.message);
          Cookies.set("step", "StepThreeDone", { expires: 7 });
          setTimeout(() => {
            navigate("/membership");
            window.location.reload();
          }, 100);
        })
        .catch((error) => {
          setContinueLoader(false);
          // console.log(error);
        });
    } else {
      toast.error("Please fill all the details to continue");
      setContinueLoader(false);
    }
  };

  useEffect(() => {
    if (captchaValue?.length > 0) {
      hitNextPlan();
    }
  }, [captchaValue]);

  return (
    <div className={`signUpFlow py-5`}>
      <div className="container position-relative pt-5">
        <div
          className={`row g-4 g-xl-5 ${
            sellerValue == "no" ? "align-items-start" : "align-items-center"
          }`}
        >
          <div className="col-12">
            <img src={logo} alt="" className="img-fluid spLogo" />
          </div>
          <div className="col-lg-6">
            <div className={`signUpLeft ${sellerValue == "no" ? "mt-5" : ""}`}>
              <h5>Are you a Seller?</h5>
              <div className="row mt-3">
                <div className="col-auto">
                  <Form.Check
                    type="radio"
                    name="seller"
                    label={`Yes`}
                    id={`check1`}
                    defaultChecked
                    onChange={() => setSellerValue("yes")}
                  />
                </div>
                <div className="col-auto">
                  <Form.Check
                    type="radio"
                    name="seller"
                    label={`No`}
                    id={`check2`}
                    onChange={() => setSellerValue("no")}
                  />
                </div>
              </div>
              <>
                <Form>
                  {sellerValue === "yes" && (
                    <>
                      <h5 className="mt-5 mb-4">Provide Seller Information</h5>
                      <Form.Group
                        className="mb-4"
                        controlId="sellerCompanyName"
                      >
                        <Form.Label>Company Name</Form.Label>
                        <div className="position-relative custom-field">
                          <i className="bi bi-building-fill position-absolute start-0 h-100 pe-1 d-flex align-items-center"></i>
                          <Form.Control
                            className={scnError ? "error ps-4" : "ps-4"}
                            type="text"
                            placeholder="Enter your company name"
                            value={sellerCompanyName}
                            onChange={checkSellerCompanyName}
                          />
                        </div>
                        {scnError && (
                          <small className="text-danger">{scnError}</small>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-4" controlId="CompanyWebsite">
                        <Form.Label>Company website</Form.Label>
                        <div className="position-relative custom-field">
                          <i className="bi bi-globe-americas position-absolute start-0 h-100 pe-1 d-flex align-items-center"></i>
                          <Form.Control
                            className={websiteError ? "error ps-4" : "ps-4"}
                            type="text"
                            placeholder="Enter your company website"
                            value={website}
                            onChange={checkWebsite}
                          />
                        </div>
                        {websiteError && (
                          <small className="text-danger">{websiteError}</small>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-4" controlId="sellerID">
                        <Form.Label>Amazon Seller ID</Form.Label>
                        <div className="position-relative custom-field">
                          <i className="bi bi-person-vcard-fill position-absolute start-0 h-100 pe-1 d-flex align-items-center"></i>
                          <Form.Control
                            className={sellerIDerror ? "error ps-4" : "ps-4"}
                            type="text"
                            placeholder="Enter your amazon seller id"
                            value={sellerID}
                            onChange={checkSellerID}
                          />
                        </div>
                        {sellerIDerror && (
                          <small className="text-danger">{sellerIDerror}</small>
                        )}
                      </Form.Group>
                    </>
                  )}
                  <ReCAPTCHA
                    sitekey="6LeQVFYpAAAAABj2fVpd9l9gJnSkugoaRgqYONv0"
                    onChange={handleRecaptchaChange}
                    size="invisible"
                  />
                  <Button
                    className="btn-main w-100 mt-3"
                    variant="transparent"
                    type="submit"
                    onClick={continueSeller}
                    disabled={continueLoader ? true : false}
                  >
                    Continue
                    {continueLoader && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="ms-2"
                      />
                    )}
                  </Button>
                </Form>
              </>
              {/* {sellerValue == 'no' && 
                <Button className="btn-main w-100 mt-5" variant="transparent" type="submit" onClick={continueSeller} disabled={continueLoader ? true : false}>
                Continue
                {continueLoader && 
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="ms-2"
                    />
                }
                </Button>
              } */}
            </div>
          </div>
          <div className="col-lg-6">
            <SignUpRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerConfirmation;
