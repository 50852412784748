import "./App.css";
import Home from "./Pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Terms from "./Pages/Terms";
import Privacy from "./Pages/Privacy";
import Copyright from "./Pages/Copyright";
import Mobiledisrupt from "./Pages/Mobiledisrupt";
// import SignUp from "./Pages/signup/SignUp";
import AsinSearch from "./Pages/signup/AsinSearch";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SellerConfirmation from "./Pages/signup/SellerConfirmation";
// import MemberShip from "./Pages/signup/MemberShip";
// import PaymentDetails from "./Pages/signup/PaymentDetails";
// import SetPassword from "./Pages/signup/SetPassword";
import Welcome from "./Pages/signup/Welcome";
import Cookies from "js-cookie";
// import { useEffect } from 'react';
import RedirectComponent from "./RedirectComponent";
import NewMembership from "./Pages/signup/NewMembership";
import SignUpNew from "./Pages/signup/SignUpNew";
import NotFound from "./Pages/NotFound";
import NewHome from "./Pages/NewHome";
function App() {
  const Progress = Cookies.get("step");
  const UUID = Cookies.get("uuid");

  // console.log("22", Progress, UUID);
  return (
    <div className="App">
      <BrowserRouter>
        <>
          <RedirectComponent />
          <Routes>
            <Route path="/terms" element={<Terms />}></Route>
            <Route path="/privacy" element={<Privacy />}></Route>
            <Route path="/copyright" element={<Copyright />}></Route>
            {/* <Route path="/" element={<Home />}></Route> */}
            <Route path="/" element={<NewHome />}></Route>
            <Route path="/ces2024" element={<Mobiledisrupt />}></Route>

            <Route path="/sign-up" element={<SignUpNew />}></Route>
            {!Progress?.length && !UUID?.length && (
              <>
                <Route
                  path="/asin-search"
                  element={<Navigate to="/sign-up" />}
                />
                <Route
                  path="/seller-details"
                  element={<Navigate to="/sign-up" />}
                />
                <Route
                  path="/membership"
                  element={<Navigate to="/sign-up" />}
                />
                <Route
                  path="/payemnt-details"
                  element={<Navigate to="/sign-up" />}
                />
                <Route
                  path="/set-password"
                  element={<Navigate to="/sign-up" />}
                />
                <Route path="/welcome" element={<Navigate to="/sign-up" />} />
              </>
            )}

            <Route path="/asin-search" element={<AsinSearch />}></Route>
            <Route
              path="/seller-details"
              element={<SellerConfirmation />}
            ></Route>

            {/* <Route path='/membership' element={<MemberShip/>}> </Route> */}
            <Route path="/membership" element={<NewMembership />}></Route>
            {/* <Route path="/payemnt-details" element={<PaymentDetails />}></Route> */}
            {/* <Route path="/set-password" element={<SetPassword />}></Route> */}
            <Route path="/welcome" element={<Welcome />}></Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
