import React from 'react'

const LinkedInicon = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    >
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.28567 6.09766H8.76167V7.33099C9.11834 6.62166 10.033 5.98433 11.407 5.98433C14.041 5.98433 14.6663 7.39633 14.6663 9.98699V14.785H11.9997V10.577C11.9997 9.10166 11.643 8.26966 10.735 8.26966C9.47567 8.26966 8.95234 9.16633 8.95234 10.5763V14.785H6.28567V6.09766ZM1.71301 14.6717H4.37967V5.98433H1.71301V14.6717ZM4.76167 3.15166C4.76177 3.37518 4.71744 3.59648 4.63127 3.80272C4.54509 4.00895 4.41878 4.196 4.25967 4.35299C3.93727 4.67341 3.50088 4.85276 3.04634 4.85166C2.5926 4.85136 2.15722 4.67246 1.83434 4.35366C1.67582 4.19614 1.54993 4.00888 1.4639 3.80261C1.37788 3.59635 1.33339 3.37514 1.33301 3.15166C1.33301 2.70033 1.51301 2.26833 1.83501 1.94966C2.1576 1.63043 2.59317 1.45146 3.04701 1.45166C3.50167 1.45166 3.93767 1.63099 4.25967 1.94966C4.58101 2.26833 4.76167 2.70033 4.76167 3.15166Z"
        fill="#F6F8FA"
    />
    </svg>

  )
}

export default LinkedInicon