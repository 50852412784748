import React from 'react'
import { Col, Container, Nav, Row ,Image } from 'react-bootstrap'
import LinkedInicon from '../Icons/LinkedInicon'
import { Link } from 'react-router-dom'
import footerLogo from '../Assets/GroupLogo9086.png'
const InnerPageFooter = () => {
  return (
    <div className='NewFooter py-5 innerPageFooter'>
          <Container className='py-lg-5'>
              <Row className='g-4 g-lg-3  justify-content-lg-between'>
                  <Col xs="auto">
                      <div className="NewFooterBlock ">
                          <img src={footerLogo} alt="" className="img-fluid mb-lg-5" />
                          <div className='nfbLFotter mt-5'>
                              <p className='mb-2'>Follow us on: <Link to="#" className='ms-1 fs-4'><LinkedInicon/></Link></p>
                          </div>
                      </div>
                  </Col>
                  <Col xs="auto">
                      <p>Quick Links</p>
                      <ul>
                          <li className='upload_csv'><Nav.Link  href='/#product_overview'>Product Overview</Nav.Link></li>
                          <li className='upload_csv'><Nav.Link  href='/#why_nformed'>Why Nformed?</Nav.Link></li>
                          <li className='dataAnatics upload_csv'>
                              <Nav.Link href="/#testimonial">Testimonials</Nav.Link>
                          </li>
                          <li className='upload_csv'><Nav.Link  href='/#pricing'>Pricing</Nav.Link></li>
                      </ul>
                  </Col>

                  <Col xs="auto">
                      <div className="newsLetter">
                          <h3>News Letter</h3>
                          <div className="newsLetterBlock">
                              <input type="email" className='form-control' placeholder='Enter Email' />
                              <button className='btn nformed-btn sign-in-btn'>Subscribe</button>
                          </div>
                          {/* <Link to="/sign-up" className='btn nformed-btn'>Get Started</Link> */}
                      </div>
                  </Col>
                  <Col xs="auto">
                      <h3>Let’s Simply Sales</h3>
                      <Link to="/sign-up" className='btn nformed-btn'>Get Started</Link>
                  </Col>
              </Row>
              <div className="text-lg-center pt-5 mt-lg-5">
                  <p>Copyright ©2023-2024 nformed. All rights reserved.</p>
                  <p className='copyrightLinks'><Link to="/privacy">Privacy Policy</Link> | <Link to="/terms">Terms & Conditions</Link> | <Link to="/">Copyright Policy</Link></p>
              </div>
          </Container>
      </div>
  )
}

export default InnerPageFooter